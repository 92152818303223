<!-- Hero Start -->
<section class="bg-half-120 bg-light d-table w-100" style="background: url('assets/images/seo/bg.png') center center;"
    id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="title-heading">
                    <h4 class="heading mt-2 mb-2">HSA Eligible</h4>
                    <h5 class="heading mt-2 mb-4 text-primary">Sexually Transmitted Diseases (STD) Tests</h5>
                    <p class="para-desc mx-auto text-muted mb-0">Learn more about HSA qualifing tests, browse HSA-Shop Directory today.</p>

                    <div class="mt-4 mb-4">
                        <a href="https://apps.apple.com/us/app/hsa/id1643850395" target="_blank" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
                        <a href="https://play.google.com/store/apps/details?id=com.thinkdiscountllc.hsa" target="_blank" class="btn btn-outline-primary mt-2">
                            <i class="uil uil-google-play"></i> Play
                            Store
                        </a>
                    </div>                   
                </div>
            </div>
            <!--end col-->

        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--end section-->
<!-- Hero End -->

<!-- FEATURES START -->
<section class="section">

    <div class="container mt-60 mb-20">
        <div class="row align-items-center">
            <div class="col-md-12 text-center">
                <a routerLink="/directory/tests/std" fragment="herpes" class="btn btn-pills btn-soft-primary mt-1"> Herpes I & II </a>
                &nbsp;
                <a routerLink="/directory/tests/std" fragment="chlamydia" class="btn btn-pills btn-soft-primary mt-1"> Chlamydia </a>
                &nbsp;
                <a routerLink="/directory/tests/std" fragment="hiv" class="btn btn-pills btn-soft-primary mt-1"> HIV 1 & 2 Antibody </a>
                &nbsp;
                <a routerLink="/directory/tests/std" fragment="hiv-rna" class="btn btn-pills btn-soft-primary mt-1"> HIV RNA Antibody </a>
                &nbsp;
                <a routerLink="/directory/tests/std" fragment="syphilis" class="btn btn-pills btn-soft-primary mt-1"> Syphilis </a>
                &nbsp;
                <a routerLink="/directory/tests/std" fragment="gonorrhea" class="btn btn-pills btn-soft-primary mt-1"> Gonorrhea </a>
            </div>
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60" id="herpes">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div class="me-lg-5">
                    <img src="assets/images/directory/embryo.png" class="img-fluid" alt="">
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="section-title">
                    <h4 class="title mb-4">Herpes I & II</h4>
                    <p class="text-muted">
                        Herpes Simplex Viruses (HSV) are categorized into two types: HSV-1 and HSV-2.
                    </p>

                    <ul class="list-unstyled text-muted">
                        <li class="mb-0">
                            <span class="text-primary h5 me-2">
                                <i class="uil uil-angle-double-right align-middle"></i>
                            </span>HSV-1 are transmitted through oral secretions or sores skin can lead to sores around the lips and mouth. Sometimes this can lead to genital herpes.
                        </li>
                        <li class="mb-0">
                            <span class="text-primary h5 me-2">
                                <i class="uil uil-angle-double-right align-middle"></i>
                            </span>HSV-2 cause sores around the genitals or rectum areas.
                        </li>
                    </ul>



                    <p class="text-muted">
                        During pregnancy, women with herpes can pass through to the baby during childbirth.
                    </p>

                    <p class="text-primary fw-bold">
                        Herpes I & II testing are HSA eligible expenses. Learn more today.
                    </p>

                    <div class="mt-4">
                        <a href="https://apps.apple.com/us/app/hsa/id1643850395" target="_blank" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
                        <a href="https://play.google.com/store/apps/details?id=com.thinkdiscountllc.hsa" target="_blank" class="btn btn-outline-primary mt-2">
                            <i class="uil uil-google-play"></i> Play
                            Store
                        </a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60" id="chlamydia">
        <div class="row align-items-center">
            <div class="col-md-6 order-1 order-md-2">
                <div class="ms-lg-5">
                    <img src="assets/images/directory/uterus.jpg" class="img-fluid" alt="">
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 order-2 order-md-1 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="section-title">
                    <h4 class="title mb-4">Chlamydia</h4>
                    <p class="text-muted">
                        Chlamydia can cause serious complications to the woman's reproductive system making it challenging to get pregnant later.
                    </p>
                    <p class="text-muted">
                        The majority of people who are infected by Chlamydia have no symptoms so you pass on Chlamydia to your partners without knowing.
                    </p>
                    <p class="text-primary fw-bold">
                        Chlamydia testing is HSA eligible expenses. Learn more today.
                    </p>

                    <div class="mt-4">
                        <a href="https://apps.apple.com/us/app/hsa/id1643850395" target="_blank" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
                        <a href="https://play.google.com/store/apps/details?id=com.thinkdiscountllc.hsa" target="_blank" class="btn btn-outline-primary mt-2">
                            <i class="uil uil-google-play"></i> Play
                            Store
                        </a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60" id="hiv">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div class="me-lg-5">
                    <img src="assets/images/directory/hiv.jpg" class="img-fluid" alt="">
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="section-title">
                    <h4 class="title mb-4">HIV 1 & 2 Antibody</h4>
                    <p class="text-muted">
                        HIV is a viral infection that attacks the immune system, causing Acquired Immunodeficiency Syndrome (AIDS). There are two types of HIV: HIV-1 and HIV-2.
                    </p>
                    <ul class="list-unstyled text-muted">
                        <li class="mb-0">
                            <span class="text-primary h5 me-2">
                                <i class="uil uil-angle-double-right align-middle"></i>
                            </span>HIV-1 is the more aggressive and commonly found type of HIV that affects Worldwide.
                        </li>
                        <li class="mb-0">
                            <span class="text-primary h5 me-2">
                                <i class="uil uil-angle-double-right align-middle"></i>
                            </span>HSV-2 is limited to the West Africa area.
                        </li>
                    </ul>



                    <p class="text-muted">
                        HIV can be transmitted through the exchange of body fluids such as such as blood, breast milk, semen and vaginal secretions or sharing of needles and syringes. There is no cure for HIV.
                    </p>

                    <p class="text-primary fw-bold">
                        HIV 1 & 2 Antibody testing are HSA eligible expenses. Learn more today.
                    </p>

                    <div class="mt-4">
                        <a href="https://apps.apple.com/us/app/hsa/id1643850395" target="_blank" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
                        <a href="https://play.google.com/store/apps/details?id=com.thinkdiscountllc.hsa" target="_blank" class="btn btn-outline-primary mt-2">
                            <i class="uil uil-google-play"></i> Play
                            Store
                        </a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60" id="hiv-rna">
        <div class="row align-items-center">
            <div class="col-md-6 order-1 order-md-2">
                <div class="ms-lg-5">
                    <img src="assets/images/directory/blood.jpg" class="img-fluid" alt="">
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 order-2 order-md-1 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="section-title">
                    <h4 class="title mb-4">HIV RNA Antibody</h4>
                    <p class="text-muted">
                        HIV is a viral infection that attacks the immune system, causing Acquired Immunodeficiency Syndrome (AIDS).
                    </p>


                    <p class="text-muted">
                        HIV RNA test detects HIV and not antibodies. Typically used to quickly detect HIV after a possible exposure because of its shorter window period.
                    </p>

                    <p class="text-primary fw-bold">
                        HIV RNA testing is HSA eligible expenses. Learn more today.
                    </p>

                    <div class="mt-4">
                        <a href="https://apps.apple.com/us/app/hsa/id1643850395" target="_blank" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
                        <a href="https://play.google.com/store/apps/details?id=com.thinkdiscountllc.hsa" target="_blank" class="btn btn-outline-primary mt-2">
                            <i class="uil uil-google-play"></i> Play
                            Store
                        </a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60" id="syphilis">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div class="me-lg-5">
                    <img src="assets/images/directory/brain.jpg" class="img-fluid" alt="">
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="section-title">
                    <h4 class="title mb-4">Syphilis</h4>
                    <p class="text-muted">
                        You can contact Syphilis through direct contact with a syphilis sore on someone else's body. The bacteria can enter your body through cuts or during sexual activity.
                    </p>
                    <p class="text-muted">
                        Left untreated, Syphilis can cause serious long-term problems such as arthritis, brain damage, and blindness.
                    </p>
                    <p class="text-muted">
                        Syphilis is a curable sexually transmitted disease (STD).
                    </p>
                    <p class="text-primary fw-bold">
                        Syphilis testing is HSA eligible expenses. Learn more today.
                    </p>

                    <div class="mt-4">
                        <a href="https://apps.apple.com/us/app/hsa/id1643850395" target="_blank" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
                        <a href="https://play.google.com/store/apps/details?id=com.thinkdiscountllc.hsa" target="_blank" class="btn btn-outline-primary mt-2">
                            <i class="uil uil-google-play"></i> Play
                            Store
                        </a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60" id="gonorrhea">
        <div class="row align-items-center">
            <div class="col-md-6 order-1 order-md-2">
                <div class="ms-lg-5">
                    <img src="assets/images/directory/gender.png" class="img-fluid" alt="">
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 order-2 order-md-1 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="section-title">
                    <h4 class="title mb-4">Gonorrhea</h4>
                    <p class="text-muted">
                        Severe allergic reactions can be life-threatening for some people. People with a family history of allergies have an increased risk of developing allergic disease.
                    </p>
                    <p class="text-muted">
                        Painful urination, pain or swellings, pus-like discharge in affected areas are some of the symptoms.
                    </p>
                    <p class="text-muted">
                        Gonorrhea is a curable sexually transmitted disease (STD), however, the damage area cannot be undone.
                    </p>
                    <p class="text-primary fw-bold">
                        Gonorrhea testing is HSA eligible expenses. Learn more today.
                    </p>

                    <div class="mt-4">
                        <a href="https://apps.apple.com/us/app/hsa/id1643850395" target="_blank" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
                        <a href="https://play.google.com/store/apps/details?id=com.thinkdiscountllc.hsa" target="_blank" class="btn btn-outline-primary mt-2">
                            <i class="uil uil-google-play"></i> Play
                            Store
                        </a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->




</section>
<!--end section-->
<!-- End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->