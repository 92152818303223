import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';


@Component({
  selector: 'app-hsa-shop',
    templateUrl: './hsa-shop.component.html',
    styleUrls: ['./hsa-shop.component.css']
})

/***
 * HSA Shop Component
 */
export class HSAShopComponent implements OnInit {

    constructor(private titleService: Title, 
        private metaService: Meta,  
        private router: Router,
        private route: ActivatedRoute) { 

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.setMetaTags();
              ///const routeData = this.route.snapshot.firstChild.data;
            //   this.titleService.setTitle(routeData['title'] || 'Default Title');
              //this.metaService.updateTag({ name: 'description', content: routeData['description'] || 'Default Description' });
              //this.metaService.updateTag({ name: 'keywords', content: routeData['keywords'] || 'default, keywords' });
            }
          });


    }

    setMetaTags(){
        const content: any = {
            title: "HSA-Shop - Your HSA-Eligible Product Directory Store",
            description: "Learn more about HSA-Shop product features and free pricing. HSA-Shop is your one stop HSA-Eligible Product Directory Store with over 7000+ of HSA-Eligible products available in savings in pre-tax. Get notifications of pricing changes of your HSA products of interest today. Our directory is accessibly via mobile application. Download HSA-Shop from iOS or Android mobile store to start your HSA savings today.",
            robots: "index,follow",
            ogType: "website",
            ogUrl: "www.hsa-shop.net",
            ogImage: "www.hsa-shop.net/assets/images/mobileapp/app_landing.PNG",
            ogSiteName: "HSA-Shop",
            twitterUsername: "",
            viewport: "width=device-width,initial-scale=1.0"
        }

        this.titleService.setTitle(content.title);

        const description: MetaDefinition = {
            name: "description",
            content: content.description
        }

        const robots: MetaDefinition = {
            name: "robots",
            content: content.robots
        }

        const ogType: MetaDefinition = {
            name: "og:type",
            content: content.ogType
        }

        const ogTitle: MetaDefinition = {
            name: "og:title",
            content: content.title
        }

        const ogDescription: MetaDefinition = {
            name: "og:description",
            content: content.description
        }

        const ogImage: MetaDefinition = {
            name: "og:image",
            content: content.ogImage
        }

        const ogUrl: MetaDefinition = {
            name: "og:url",
            content: content.ogUrl
        }

        const ogSiteName: MetaDefinition = {
            name: "og:site_name",
            content: content.ogSiteName
        }

        const twitterTitle: MetaDefinition = {
            name: "twitter:title",
            content: content.title
        }

        const twitterDescription: MetaDefinition = {
            name: "twitter:description",
            content: content.description
        }

        const twitterImage: MetaDefinition = {
            name: "twitter:image",
            content: content.ogImage
        }

        const twitterUrl: MetaDefinition = {
            name: "twitter:url",
            content: content.ogUrl
        }

        // <meta name="twitter:site" content="@USERNAME">
        // <meta name="twitter:creator" content="@USERNAME">

        const viewport: MetaDefinition = {
            name: "viewport",
            content: content.viewport
        }


        const keywords: MetaDefinition = {
            name: "keywords",
            content: "hsa, health savings account, hsa shop, hsa store, hsa eligible, hsa eligible products, hsa product directory, hsa savings"
        }

        this.metaService
            .addTags([description, robots, ogType, ogTitle,
                ogDescription, ogImage, ogUrl, ogSiteName,
                twitterTitle, twitterDescription, twitterImage, twitterUrl,
                viewport, keywords]);
    }


  /***
   * Client Review Owl Slider
   */
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      900: {
        items: 3
      }
    },
    nav: false
  };

  /**
   * Simple Pricing Data
   */
  simple_pricingData = [
    {
      warning: "Best",
      title: "Free",
      price: 0,
          list: ["Browse over 7000+ of HSA-Eligible Products",
              "Full-Access to HSA-Eligible Product Directory",
              "Product Search Feature",
              "Pricing Change Notification"]
    }
  ];

    ngOnInit(): void {
       
    }

    ngOnDestroy(): void {
        this.titleService.setTitle("HSA-Shop - Your HSA-Eligible Product Directory Store");
        this.metaService.removeTag("name='description'");
        this.metaService.removeTag("name='robots'");
        this.metaService.removeTag("name='og:type'");
        this.metaService.removeTag("name='og:title'");
        this.metaService.removeTag("name='og:description'");
        this.metaService.removeTag("name='og:image'");
        this.metaService.removeTag("name='og:url'");
        this.metaService.removeTag("name='og:site_name'");
        this.metaService.removeTag("name='twitter:title'");
        this.metaService.removeTag("name='twitter:description'");
        this.metaService.removeTag("name='twitter:image'");
        this.metaService.removeTag("name='twitter:url'");
        this.metaService.removeTag("name='viewport'");
        this.metaService.removeTag("name='keywords'");
    }
}
