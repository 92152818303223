<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" id="product">
  <div class="container">
    <div class="row mt-5 align-items-center">
      <div class="col-lg-6 col-md-7">
          <div class="title-heading">
              <h1 class="heading mb-3">Welcome to <span class="text-primary">HSA-Shop</span></h1>
              <h3>Find 7000+ HSA-Eligible Products Savings</h3>
              <p class="para-desc text-muted">
                  Download our application to browse our Health Savings Accounts (HSA) eligible products directory today!
              </p>
              <div class="mt-4">
                  <a href="https://apps.apple.com/us/app/hsa/id1643850395" target="_blank" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
                  <a href="https://play.google.com/store/apps/details?id=com.thinkdiscountllc.hsa" target="_blank" class="btn btn-outline-primary mt-2">
                      <i class="uil uil-google-play"></i> Play
                      Store
                  </a>
              </div>
          </div>
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="text-md-end text-center ms-lg-4">
          <img src="assets/images/mobileapp/app_landing.PNG" class="img-fluid" alt="HSA-Shop, HSA-Eligible Product Directory Store, Mobile Application Landing Page">
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-light">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Features Start -->
<section class="section bg-light" id="features">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Why Use <span class="text-primary">HSA-Shop</span> Application?</h4>
          <p class="text-muted para-desc mb-0 mx-auto"><span class="text-primary fw-bold">HSA-Eligible Products</span> are qualifying health products that you can purchase using your tax-free money from your Health Savings Accounts (HSA).</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center align-items-center">
        <div class="col-lg-8 col-md-8">
            <div class="row mt-4 pt-2">
                <div class="col-md-6 col-12">
                    <div class="d-flex features pt-4 pb-4">
                        <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                            <i-feather name="align-justify" class="fea icon-ex-md text-primary"></i-feather>
                        </div>
                        <div class="flex-1">
                            <h4 class="title">Product Directory</h4>
                            <p class="text-muted para mb-0">Browse our <span class="text-primary fw-bold">7000+ HSA products directory</span> to learn more about HSA-Eligible products.</p>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-md-6 col-12">
                    <div class="d-flex features pt-4 pb-4">
                        <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                            <i-feather name="heart" class="fea icon-ex-md text-primary"></i-feather>
                        </div>
                        <div class="flex-1">
                            <h4 class="title">FREE ! ! !</h4>
                            <p class="text-muted para mb-0">Access to our <span class="text-primary fw-bold">directory is free</span> of charge.</p>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-md-6 col-12">
                    <div class="d-flex features pt-4 pb-4">
                        <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                            <i-feather name="bell" class="fea icon-ex-md text-primary"></i-feather>
                        </div>
                        <div class="flex-1">
                            <h4 class="title">Price Notification</h4>
                            <p class="text-muted para mb-0">Get notifications on <span class="text-primary fw-bold">price changes</span> of your HSA products of interest.</p>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-md-6 col-12">
                    <div class="d-flex features pt-4 pb-4">
                        <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                            <i-feather name="dollar-sign" class="fea icon-ex-md text-primary"></i-feather>
                        </div>
                        <div class="flex-1">
                            <h4 class="title">Savings Estimation</h4>
                            <p class="text-muted para mb-0">Get estimations on how much you can <span class="text-primary fw-bold">save</span> by using HSA.</p>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-md-6 col-12">
                    <div class="d-flex features pt-4 pb-4">
                        <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                            <i-feather name="star" class="fea icon-ex-md text-primary"></i-feather>
                        </div>
                        <div class="flex-1">
                            <h4 class="title">Shopping List</h4>
                            <p class="text-muted para mb-0">Create your <span class="text-primary fw-bold">shopping lists</span> of HSA products.</p>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-md-6 col-12">
                    <div class="d-flex features pt-4 pb-4">
                        <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                            <i-feather name="smartphone" class="fea icon-ex-md text-primary"></i-feather>
                        </div>
                        <div class="flex-1">
                            <h4 class="title">Mobile Application</h4>
                            <p class="text-muted para mb-0">Mobile support. Access our directory via <span class="text-primary fw-bold">iOS and Andriod</span></p>
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->

            <div class="row justify-content-center mt-4">
                <div class="col-md-6 col-12">
                    <a href="https://apps.apple.com/us/app/hsa/id1643850395" target="_blank" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
                    <a href="https://play.google.com/store/apps/details?id=com.thinkdiscountllc.hsa" target="_blank" class="btn btn-outline-primary mt-2">
                        <i class="uil uil-google-play"></i> Play
                        Store
                    </a>
                </div>

            </div>
            <!--end row-->
        </div>
      <!--end col-->



      <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2 text-center text-md-end">
        <img src="assets/images/mobileapp/app_all_categories.PNG" class="img-fluid" alt="HSA-Shop, HSA-Eligible Product Directory Store, All Categories Page">
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Feature End -->

<!-- Showcase Start -->
<section class="section bg-light" id="learnmore">
  
  <div class="container mt-0 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-0 pb-2">
          <h4 class="title mb-4">Learn more about <span class="text-primary">Health Savings Account (HSA)</span></h4>          
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center">
      <div class=" mt-4 pt-2 text-center">
        <ul ngbNav #nav="ngbNav"
          class="nav-pills nav-justified flex-column flex-sm-row rounded col-lg-8 col-md-12 offset-md-2">
          <li ngbNavItem>
            <a ngbNavLink class="rounded">
              <div class="text-center py-2">
                  <h6 class="mb-0">HSA-Eligible Products</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <div class="row align-items-center">
                  <div class="col-md-6">
                      <img src="assets/images/mobileapp/first_aid_products.png" class="img-fluid mx-auto d-block" alt="Example HSA-Eligible Products, Sample View #1">
                      <br />
                      <img src="assets/images/mobileapp/first_aid_products_2.png" class="img-fluid mx-auto d-block" alt="Example HSA-Eligible Products, Sample View #2">
                  </div>
                <!--end col-->

                <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div class="section-title text-md-start">
                        <h4 class="title mb-4"><i class="uil uil-angle-double-right text-primary"></i> What is <span class="text-primary">HSA-Eligible Products</span>?</h4>
                        <p class="text-muted"></p>
                        <ul class="list-unstyled text-muted">
                            <li class="mb-4">
                                <span class="text-primary h5 me-2">
                                    <i class="uil uil-check-circle align-middle"></i>
                                </span><span class="text-primary fw-bold">HSA-Eligible Products</span> are qualifying health products that you can purchase using your tax-free money from your Health Savings Accounts (HSA).
                            </li>
                            <li class="mb-4">
                                <span class="text-primary h5 me-2">
                                    <i class="uil uil-check-circle align-middle"></i>
                                </span>First aid and health monitoring supplies: bandages, compression socks, blood pressure cuffs and glucometers.
                            </li>
                            <li class="mb-4">
                                <span class="text-primary h5 me-2">
                                    <i class="uil uil-check-circle align-middle"></i>
                                </span>Over-the-counter drugs including allergy medicine, cold medicine and pain relievers.
                            </li>
                            <li class="mb-4">
                                <span class="text-primary h5 me-2">
                                    <i class="uil uil-check-circle align-middle"></i>
                                </span>Feminine hygiene products such as tampons and pads etc.
                            </li>
                            <li class="mb-4">
                                <span class="text-primary h5 me-2">
                                    <i class="uil uil-check-circle align-middle"></i>
                                </span>And many more HSA-Eligible products.
                            </li>
                        </ul>
                        <br />
                        <a href="https://www.irs.gov/publications/p969" target="_blank" class="mt-3 fw-bold h5 text-primary">
                            <i class="uil uil-angle-right-b"></i>IRS.Gov Pub-969 on Health Savings Accounts (HSA) and Other Tax-Favored Health Plans
                        </a>
                        <br /><br/>
                        <a href="https://www.irs.gov/forms-pubs/about-publication-502" target="_blank" class="mt-3 fw-bold h5 text-primary">
                            <i class="uil uil-angle-right-b"></i>IRS.Gov Pub-502 on Medical and Dental Expenses
                        </a>
                        <div class="mt-4">
                            <a href="https://apps.apple.com/us/app/hsa/id1643850395" target="_blank" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
                            <a href="https://play.google.com/store/apps/details?id=com.thinkdiscountllc.hsa" target="_blank" class="btn btn-outline-primary mt-2">
                                <i class="uil uil-google-play"></i> Play
                                Store
                            </a>
                        </div>
                    </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </ng-template>
          </li>
          <li ngbNavItem>
            <a ngbNavLink class="rounded">
              <div class="text-center py-2">
                  <h6 class="mb-0">Health Savings Account (HSA)</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <div class="row align-items-center">
                <div class="col-md-6">
                  <img src="assets/images/mobileapp/piggy_bank_savings_money_light.png" class="img-fluid mx-auto d-block" alt="Health Savings Account (HSA) offers savings on HSA-Eligible products in pre-tax money">
                </div>
                <!--end col-->

                <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <div class="section-title text-md-start">
                    <h4 class="title mb-4"><i class="uil uil-angle-double-right text-primary"></i> What is <span class="text-primary">Health Savings Account (HSA)</span>?</h4>
                    <p class="text-muted"></p>
                    <ul class="list-unstyled text-muted">
                        <li class="mb-4">
                            <span class="text-primary h5 me-2">
                                <i class="uil uil-check-circle align-middle"></i>
                            </span>Health Savings Account is a type of savings account where you can deposit your <span class="text-primary fw-bold">pre-tax money to pay for qualified medical expenses</span>.
</li>
                        <li class="mb-4">
                            <span class="text-primary h5 me-2">
                                <i class="uil uil-check-circle align-middle"></i>
                            </span>Health Savings Account is designed to help you <span class="text-primary fw-bold">lower your overall health care costs</span> by letting you use untaxed dollars from the account to pay for deductibles, copayments, coinsurance, and some other health-related expenses.
</li>
                        <li class="mb-4">
                            <span class="text-primary h5 me-2">
                                <i class="uil uil-check-circle align-middle"></i>
                            </span>You can only contribute to Health Savings Account only if you have a <span class="text-primary fw-bold">High Deductible Health Plan (HDHP)</span>.
</li>                                              
                    </ul>
                    <br/>
                    <a href="https://www.healthcare.gov/glossary/health-savings-account-hsa/" target="_blank" class="mt-3 fw-bold h5 text-primary">
                        <i class="uil uil-angle-right-b"></i>HealthCare.Gov on HSA
                    </a>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </ng-template>
          </li>
          <li ngbNavItem>
            <a ngbNavLink class="rounded">
              <div class="text-center py-2">
                  <h6 class="mb-0">High Deductible Health Plan (HDHP)</h6>
              </div>
            </a>
            <ng-template ngbNavContent>
              <div class="row align-items-center">
                <div class="col-md-6">
                  <img src="assets/images/mobileapp/health_insurance.png" class="img-fluid mx-auto d-block" alt="Only High Deductible Health Plan (HDHP) Health Insurance Plan can be combined with Health Savings Account (HSA)">
                </div>
                <!--end col-->

                <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div class="section-title text-md-start">
                        <h4 class="title mb-4"><i class="uil uil-angle-double-right text-primary"></i> What is <span class="text-primary">High Deductible Health Plan (HDHP)</span>?</h4>
                        <p class="text-muted"></p>
                        <ul class="list-unstyled text-muted">
                            <li class="mb-4">
                                <span class="text-primary h5 me-2">
                                    <i class="uil uil-check-circle align-middle"></i>
                                </span>High Deductible Health Plan (HDHP) are heath insurance plans that have a <span class="text-primary fw-bold">higher deductible amount</span> than a traditional insurance plan
                            </li>
                            <li class="mb-4">
                                <span class="text-primary h5 me-2">
                                    <i class="uil uil-check-circle align-middle"></i>
                                </span>The Monthly Premiums of a High Deductible Health Plan (HDHP) is usaully lower, but the deductible amount will be higher. This means that you will be <span class="text-primary fw-bold">paying more for health expenses</span> before your health insurance company starts to cover your health expenses.
                            </li>
                            <li class="mb-4">
                                <span class="text-primary h5 me-2">
                                    <i class="uil uil-check-circle align-middle"></i>
                                </span>Only High Deductible Health Plan (HDHP) can be <span class="text-primary fw-bold">combined</span> with Health Savings Account.
                            </li>
                        </ul>
                        <br />
                        <a href="https://www.healthcare.gov/glossary/high-deductible-health-plan/" target="_blank" class="mt-3 fw-bold h5 text-primary">
                            <i class="uil uil-angle-right-b"></i>HealthCare.Gov on HDHP
                        </a>
                    </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="col-12 mt-4 pt-2"></div>
      </div>
    </div>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Showcase End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Price Start -->
<section class="section" id="pricing">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4"><span class="text-primary">HSA-Shop</span> Pricing</h4>
              <p class="text-muted para-desc mb-0 mx-auto">Full-fledged features available for free.</p>
          </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <app-simple-pricing [simple_pricingData]="simple_pricingData"></app-simple-pricing>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Price End -->

<!-- Testi n Download cta start -->
<section class="section pt-0" id="downloads">
  <div class="container">

    <div class="row mt-md-5 pt-md-3 mt-4 pt-2 mt-sm-0 pt-sm-0 justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title mb-4">Download <span class="text-primary">HSA-Shop</span> App Now !</h4>
          <p class="text-muted para-desc mx-auto">
              Start <span class="text-primary fw-bold">saving on HSA-Eligible products</span> by browsing <span class="text-primary fw-bold">HSA-Shop Directory</span> today.
</p>
          <div class="mt-4">
            <a href="https://apps.apple.com/us/app/hsa/id1643850395" target="_blank" class="btn btn-primary m-1"><i class="uil uil-apple"></i> App Store</a>
            <a href="https://play.google.com/store/apps/details?id=com.thinkdiscountllc.hsa" target="_blank" class="btn btn-outline-primary m-1"><i class="uil uil-google-play"></i> Play
              Store</a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Test in Download cta End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-footer">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->


<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#product'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->