<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" id="home">
    <div class="container">
        <div class="row mt-5 align-items-center position-relative" style="z-index: 1;">
            <div class="col-lg-6">
                <div class="title-heading text-center text-lg-start">
                    <span class="badge rounded-lg bg-soft-primary">HSA</span>
                    <h4 class="heading fw-bold mb-3 mt-3">What is HSA? <br> (Health Savings Account)</h4>
                    <p class="para-desc text-muted mx-auto mx-lg-start mb-0">To the right is our video on youtube for
                        "What is HSA".</p>
                    <div class="mt-3">
                        <a href="javascript:void(0)" class="btn btn-primary me-2 mt-2">Download our FREE app</a>
                        <!-- <a href="javascript:void(0)" class="btn btn-soft-primary mt-2">Get a live video</a> -->
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-6 mt-4 pt-2 mt-lg-0 pt-lg-0">
                <div class="card border-0 shadow rounded ms-lg-4 overflow-hidden">
                    <div class="d-flex p-2 bg-light justify-content-between align-items-center">
                        <div>
                            <a href="javascript:void(0)" class="text-danger"><i class="mdi mdi-circle"></i></a>
                            <a href="javascript:void(0)" class="text-warning"><i class="mdi mdi-circle"></i></a>
                            <a href="javascript:void(0)" class="text-success"><i class="mdi mdi-circle"></i></a>
                        </div>

                        <small class="fw-bold"><i class="mdi mdi-circle-medium text-success"></i> HSA</small>
                    </div>
                    <div class="bg-light px-2 position-relative">
                        <video class="w-100 rounded" controls autoplay loop>
                            <source src="assets/images/video/call.mp4" type="video/mp4">
                        </video>

                        <div class="position-absolute top-0 start-0">
                            <div class="mt-2 ms-3">
                                <span class="badge bg-danger">LIVE</span>
                                <small class="mb-0 text-white title-dark">4 Attendance</small>
                            </div>
                        </div>
                    </div>

                    <div class="p-2 bg-light">
                        <p class="text-muted mb-0"><b>*</b> Check out, like, and subscribe to our channel.</p>
                        <p class="text-muted mb-0"><b>Direct Link: </b> <a
                                href="youtube.com">https://www.youtube.com/</a></p>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Partners start -->

<section class="py-5 border-top">
    <div class="container">
        <div class="row justify-content-center" id="counter">
            <div class="col-12 text-center">
                <h1 class="mb-0 mt-3">What is HSA (Health Savings Account)? <span class="text-primary fw-bold"> </span>
                </h1>
            </div>
            <!--end col-->
        </div>
        <p>A Health Savings Account (HSA) is a tax-advantaged savings account designed to help individuals with
            high-deductible health plans (HDHPs) save for medical expenses. It offers multiple tax benefits, making it
            an attractive option for those looking to manage their healthcare costs effectively. HSAs were established
            in 2003 as part of the Medicare Prescription Drug, Improvement, and Modernization Act. The core idea behind
            HSAs is to provide a financial cushion for individuals who have higher out-of-pocket expenses due to their
            HDHPs, which require a higher deductible to be paid before insurance coverage kicks in.</p>

        <p>One of the primary advantages of an HSA is its triple tax benefit. Contributions to an HSA are
            tax-deductible, meaning they reduce your taxable income for the year. For example, if you contribute $3,000
            to your HSA, your taxable income is reduced by that amount, lowering your overall tax bill. Additionally,
            the money in the account grows tax-free, so any interest or investment earnings are not subject to tax as
            long as they remain in the account. Finally, withdrawals from an HSA are also tax-free, provided they are
            used for qualified medical expenses, which include a broad range of healthcare costs such as doctor visits,
            prescription medications, dental care, and vision care.</p>

        <p>HSAs are highly flexible and portable. The funds in the account belong to the account holder, not the
            employer, meaning you can take your HSA with you if you change jobs or health plans. There is no "use it or
            lose it" rule, which is common with other types of healthcare accounts like Flexible Spending Accounts
            (FSAs). Any unused funds roll over from year to year, allowing the account to grow over time. This feature
            makes HSAs not only a tool for managing current healthcare costs but also a way to save for future medical
            expenses, including those in retirement.</p>

        <p>To be eligible for an HSA, you must be enrolled in a high-deductible health plan (HDHP). The IRS sets
            specific guidelines for what qualifies as an HDHP, including minimum deductible amounts and maximum
            out-of-pocket limits. You cannot be covered by any other health plan that is not an HDHP, be enrolled in
            Medicare, or be claimed as a dependent on someone else’s tax return. Contribution limits are also set by the
            IRS and are subject to change annually. For 2023, the contribution limits were $3,850 for individuals and
            $7,750 for families, with an additional $1,000 catch-up contribution allowed for individuals aged 55 and
            older.</p>

        <p>HSAs also offer investment opportunities, similar to retirement accounts. Once the account balance reaches a
            certain threshold, usually around $1,000 or $2,000, many HSA providers allow you to invest the funds in
            stocks, bonds, mutual funds, or other investment vehicles. This can significantly increase the value of the
            account over time, especially if you are able to contribute regularly and the investments perform well. This
            feature enhances the long-term savings potential of an HSA, making it a dual-purpose tool for both
            healthcare and retirement savings.</p>

        <p>In conclusion, a Health Savings Account (HSA) is a powerful financial tool for individuals with
            high-deductible health plans. It provides a tax-advantaged way to save for medical expenses, offering
            immediate tax deductions, tax-free growth, and tax-free withdrawals for qualified expenses. The flexibility
            and portability of HSAs, along with the ability to invest the funds, make them an attractive option for
            managing both current and future healthcare costs. By understanding and utilizing the benefits of an HSA,
            individuals can better manage their health expenses and potentially enhance their financial stability.</p>

        <br>
        <!--end row-->
        <h3>Key Features of HSAs</h3>
        <ul>
            <li><strong>Eligibility:</strong>
                <ul>
                    <li>Must be enrolled in a high-deductible health plan (HDHP).</li>
                    <li>Cannot be enrolled in other non-HDHP health insurance.</li>
                    <li>Cannot be claimed as a dependent on someone else's tax return.</li>
                    <li>Cannot be enrolled in Medicare.</li>
                </ul>
            </li>
            <li><strong>Contributions:</strong>
                <ul>
                    <li>Can be made by you, your employer, or both.</li>
                    <li>Contributions are pre-tax if made through payroll deductions or tax-deductible if made directly.
                    </li>
                </ul>
            </li>
            <li><strong>Tax Advantages:</strong>
                <ul>
                    <li>Contributions are tax-deductible, reducing your taxable income.</li>
                    <li>Earnings grow tax-free.</li>
                    <li>Withdrawals for qualified medical expenses are tax-free.</li>
                </ul>
            </li>
            <li><strong>Qualified Medical Expenses:</strong>
                <ul>
                    <li>Includes doctor visits, prescriptions, dental care, vision care, and certain over-the-counter
                        medications.</li>
                </ul>
            </li>
            <li><strong>Portability:</strong>
                <ul>
                    <li>The funds belong to you, regardless of your employment status or health insurance coverage.</li>
                </ul>
            </li>
            <li><strong>Rollover:</strong>
                <ul>
                    <li>Unused funds roll over from year to year.</li>
                </ul>
            </li>
            <li><strong>Investment Options:</strong>
                <ul>
                    <li>Many providers offer investment options similar to a retirement account.</li>
                </ul>
            </li>
            <li><strong>Contribution Limits:</strong>
                <ul>
                    <li>For 2023, the limits were $3,850 for individuals and $7,750 for families.</li>
                    <li>People aged 55 and older can make an additional "catch-up" contribution of $1,000.</li>
                </ul>
            </li>
            <li><strong>Withdrawal Rules:</strong>
                <ul>
                    <li>Withdrawals for qualified medical expenses are tax-free.</li>
                    <li>Non-qualified withdrawals are subject to income tax and a 20% penalty if taken before age 65.
                        After age 65, they are subject to income tax but no penalty.</li>
                </ul>
            </li>
        </ul>

        <h2>Benefits of an HSA</h2>
        <ul>
            <li><strong>Tax Savings:</strong> Triple tax advantage (tax-deductible contributions, tax-free growth, and
                tax-free withdrawals for qualified expenses).</li>
            <li><strong>Long-Term Savings:</strong> Ability to save for future medical expenses, even into retirement.
            </li>
            <li><strong>Flexibility:</strong> Funds can be used for a wide range of medical expenses.</li>
            <li><strong>Portability and Rollover:</strong> Unused funds roll over year to year and the account remains
                yours even if you change jobs.</li>
        </ul>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Partners End -->

<!-- Start -->
<section class="section bg-light">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <h4 class="title mb-4">Download our FREE mobile app to get <strong>more</strong> of the following
                    </h4>
                    <p class="text-muted para-desc mb-0 mx-auto"><strong>Over 7,000+ HSA eligible items</strong> for
                        sale.<br><span class="text-primary fw-bold">Check on product HSA (Health Savings Account)
                            eligibility </span></p>
                </div>
            </div>
            <!--end col-->
        </div>

        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center">
                    <div class="mt-4">
                        <a href="javascript:void(0)" class="btn btn-primary m-1"><i class="uil uil-apple"></i> App
                            Store</a>
                        <a href="javascript:void(0)" class="btn btn-outline-primary m-1"><i
                                class="uil uil-google-play"></i> Play Store</a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row justify-content-center">
            <div class="col-xl-10">
                <div class="row">
                    <div class="col-md-6 mt-4 pt-2">
                        <div class="d-flex features feature-clean p-4 bg-white shadow rounded">
                            <div class="icons text-primary d-block text-center rounded">
                                <i class="uil uil-comment-verify d-block rounded h3 mb-0"></i>
                            </div>
                            <div class="flex-1 content ms-4">
                                <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Make one-to-one
                                        calls</a></h5>
                                <p class="text-muted mb-0">The most well-known dummy text is the, which is said to have
                                    originated in the 16th century.</p>
                            </div>
                        </div>
                    </div>

                    <!--end col-->

                    <div class="col-md-6 mt-4 pt-2">
                        <div class="d-flex features feature-clean p-4 bg-white shadow rounded">
                            <div class="icons text-primary d-block text-center rounded">
                                <i class="uil uil-database-alt d-block rounded h3 mb-0"></i>
                            </div>
                            <div class="flex-1 content ms-4">
                                <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Duo uses end-to-end
                                        encryption</a></h5>
                                <p class="text-muted mb-0">The most well-known dummy text is the, which is said to have
                                    originated in the 16th century.</p>
                            </div>
                        </div>
                    </div>
                    <!--end col-->

                    <div class="col-md-6 mt-4 pt-2">
                        <div class="d-flex features feature-clean p-4 bg-white shadow rounded">
                            <div class="icons text-primary d-block text-center rounded">
                                <i class="uil uil-image d-block rounded h3 mb-0"></i>
                            </div>
                            <div class="flex-1 content ms-4">
                                <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Let friends know that
                                        you’ve joined</a></h5>
                                <p class="text-muted mb-0">The most well-known dummy text is the, which is said to have
                                    originated in the 16th century.</p>
                            </div>
                        </div>
                    </div>
                    <!--end col-->

                    <div class="col-md-6 mt-4 pt-2">
                        <div class="d-flex features feature-clean p-4 bg-white shadow rounded">
                            <div class="icons text-primary d-block text-center rounded">
                                <i class="uil uil-video d-block rounded h3 mb-0"></i>
                            </div>
                            <div class="flex-1 content ms-4">
                                <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Set up groups for your
                                        friends & family</a></h5>
                                <p class="text-muted mb-0">The most well-known dummy text is the, which is said to have
                                    originated in the 16th century.</p>
                            </div>
                        </div>
                    </div>
                    <!--end col-->

                    <div class="col-12 mt-4 pt-2 text-center">
                        <a href="javascript:void(0)" class="text-primary h6">Explore features <i-feather
                                name="arrow-right" class="fea icon-sm"></i-feather></a>
                    </div>
                    <!--end col-->

                </div>

                <div class="row justify-content-center">
                    <div class="col-12">
                        <div class="section-title text-center">
                            <div class="mt-4">
                                <a href="javascript:void(0)" class="btn btn-outline-primary m-1"><i
                                        class="uil uil-apple"></i> App
                                    Store</a>
                                <a href="javascript:void(0)" class="btn btn-primary m-1"><i
                                        class="uil uil-google-play"></i> Play Store</a>
                            </div>
                        </div>
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="card rounded border-0 overflow-hidden">
                    <div class="row g-0">
                        <div class="col-md-6">
                            <div class="position-relative">
                                <a href="javascript:void(0)"><img src="assets/images/video/01.jpg" class="img-fluid"
                                        alt=""></a>

                                <div class="position-absolute bottom-0 start-50 translate-middle-x">
                                    <div class="mb-3">
                                        <a href="javascript:void(0)" class="btn btn-pills btn-icon btn-danger"><i
                                                class="uil uil-phone icons"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--end col-->

                        <div class="col-md-6">
                            <div class="row g-0">
                                <div class="col-md-6 col-4">
                                    <div class="position-relative">
                                        <a href="javascript:void(0)"><img src="assets/images/video/02.jpg"
                                                class="img-fluid" alt=""></a>

                                        <div class="position-absolute bottom-0 start-50 translate-middle-x">
                                            <div class="mb-3">
                                                <a href="javascript:void(0)"
                                                    class="btn btn-pills btn-sm btn-icon btn-primary"><i
                                                        class="uil uil-comment icons"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->

                                <div class="col-md-6 col-4">
                                    <div class="position-relative">
                                        <a href="javascript:void(0)"><img src="assets/images/video/03.jpg"
                                                class="img-fluid" alt=""></a>

                                        <div class="position-absolute bottom-0 start-50 translate-middle-x">
                                            <div class="mb-3">
                                                <a href="javascript:void(0)"
                                                    class="btn btn-pills btn-sm btn-icon btn-primary"><i
                                                        class="uil uil-comment icons"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->

                                <div class="col-md-6 col-4">
                                    <div class="position-relative">
                                        <a href="javascript:void(0)"><img src="assets/images/video/05.jpg"
                                                class="img-fluid" alt=""></a>

                                        <div class="position-absolute bottom-0 start-50 translate-middle-x">
                                            <div class="mb-3">
                                                <a href="javascript:void(0)"
                                                    class="btn btn-pills btn-sm btn-icon btn-primary"><i
                                                        class="uil uil-comment icons"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->

                                <div class="col-md-6 col-4">
                                    <div class="position-relative">
                                        <a href="javascript:void(0)"><img src="assets/images/video/04.jpg"
                                                class="img-fluid" alt=""></a>

                                        <div class="position-absolute bottom-0 start-50 translate-middle-x">
                                            <div class="mb-3">
                                                <a href="javascript:void(0)"
                                                    class="btn btn-pills btn-sm btn-icon btn-primary"><i
                                                        class="uil uil-comment icons"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->

                                <div class="col-md-6 col-4">
                                    <div class="position-relative">
                                        <a href="javascript:void(0)"><img src="assets/images/video/06.jpg"
                                                class="img-fluid" alt=""></a>

                                        <div class="position-absolute bottom-0 start-50 translate-middle-x">
                                            <div class="mb-3">
                                                <a href="javascript:void(0)"
                                                    class="btn btn-pills btn-sm btn-icon btn-primary"><i
                                                        class="uil uil-comment icons"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->

                                <div class="col-md-6 col-4">
                                    <div class="position-relative">
                                        <a href="javascript:void(0)"><img src="assets/images/video/07.jpg"
                                                class="img-fluid" alt=""></a>

                                        <div class="position-absolute bottom-0 start-50 translate-middle-x">
                                            <div class="mb-3">
                                                <a href="javascript:void(0)"
                                                    class="btn btn-pills btn-sm btn-icon btn-primary"><i
                                                        class="uil uil-comment icons"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--end col-->
                            </div>
                            <!--end row-->
                        </div>
                        <!--end col-->
                    </div>
                    <!--end row-->
                </div>
                <!--end card-->
            </div>
            <!--end col-->

            <div class="col-lg-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="section-title ms-lg-5">
                    <h4 class="title mb-4">Get the group together</h4>
                    <p class="text-muted">Keep the fun times rolling with your weekly game nights over group calling.
                        Available for 50 friends at a time and works on Android phones, iPhones, iPads and tablets. You
                        can also join a group call with just a link.</p>
                    <ul class="list-unstyled text-muted">
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                                    class="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for
                            Tomorrow</li>
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                                    class="uil uil-check-circle align-middle"></i></span>Create your own skin to match
                            your brand</li>
                    </ul>
                    <a href="javascript:void(0)" class="mt-3 h6 text-primary">Let's Chat Now <i
                            class="uil uil-angle-right-b"></i></a>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- Start -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center mb-4 pb-2">
                    <h4 class="title mb-4">Some of our happy customers</h4>
                    <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row justify-content-center">
            <div class="col-lg-12 mt-4">
                <!-- <app-testimonial [testimonialData]="testimonialData"></app-testimonial> -->
            </div>
            <!--end col-->
        </div>
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-6 order-2 order-lg-1 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="section-title me-lg-5">
                    <h4 class="title mb-4">Keep calls private with end-to-end encryption</h4>
                    <p class="text-muted">This prevents repetitive patterns from impairing the overall visual impression
                        and facilitates the comparison of different typefaces. Furthermore, it is advantageous when the
                        dummy text is relatively realistic.</p>
                    <ul class="list-unstyled text-muted">
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                                    class="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for
                            Tomorrow</li>
                        <li class="mb-0"><span class="text-primary h5 me-2"><i
                                    class="uil uil-check-circle align-middle"></i></span>Create your own skin to match
                            your brand</li>
                    </ul>
                    <a href="javascript:void(0)" class="mt-3 h6 text-primary">Find Out More <i
                            class="uil uil-angle-right-b"></i></a>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-6 order-1 order-lg-2">
                <img src="assets/images/video/cta.png" class="img-fluid" alt="">
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title text-center">
                    <h4 class="title mb-4">Get started with <span class="text-primary fw-bold">Landrick</span> for free
                    </h4>
                    <p class="text-muted para-desc mx-auto">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>

                    <div class="mt-4">
                        <a href="javascript:void(0)" class="btn btn-primary m-1"><i class="uil uil-apple"></i> App
                            Store</a>
                        <a href="javascript:void(0)" class="btn btn-outline-primary m-1"><i
                                class="uil uil-google-play"></i> Play Store</a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>


    <div class="container mt-100 mt-60">
        <div class="row ">
          <div class="col-6 ">
            <div class="container mt-100 mt-60">
                <div class="row ">
                    <div class="col-12">
                        <div class="card shadow rounded border-0">
                            <div class="card-body py-5">
                                <h4 class="card-title">Did you find this page useful?</h4>
                                <div class="custom-form mt-3">
                                    <p id="error-msg" class="mb-0"></p>
                                    <div id="simple-msg"></div>
                                    <div class="row">
                                        <!--end col-->
        
                                        <div class="col-12">
                                            <div class="mb-3">
                                                <label class="form-label">Leave your feedback here <strong>(optional)</strong></label>
                                                <div class="form-icon position-relative">
                                                    <i-feather name="message-circle" class="fea icon-sm icons clearfix">
                                                    </i-feather>
                                                    <textarea [(ngModel)]="comments" name="comments" id="comments" rows="4"
                                                        class="form-control ps-5" placeholder="Optional Message Here..."></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <label class="form-label">Vote and sumbit your feedback</label>
                                        <div class="col-6">
                                                <button (click)="submitContactForm()" id="submit" name="send"
                                                    class="btn btn-primary"><i-feather name="thumbs-up" class="icons"></i-feather> Useful</button>
                                        </div>
                                        <div class="col-6">
                                                <button (click)="submitContactForm()" id="submit" name="send"
                                                class="btn btn-danger"><i-feather name="thumbs-down" class="icons"></i-feather> Not Useful</button>
                                    </div>
                                        <!--end col-->
                                    </div>
                                    <!--end row-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div class="col-6 ">
            <div class="container mt-100 mt-60">
                <div class="row">
                    <div class="col-12">
                        <div class="card shadow rounded border-0">
                            <div class="card-body py-5">
                                <h4 class="card-title">Subscribe to our Newsletter</h4>
                                <div class="custom-form mt-3">
                                    <div class="row  mt-4 pt-2">
                                        <div class="col-lg-12 col-md-10">
                                          <div class="subcribe-form">
                                            <form class="ms-0">
                                              <input type="email" id="email" name="email" class="bg-white shadow rounded-pill" placeholder="E-mail Address">
                                              <button type="submit" class="btn btn-pills btn-primary">Subscribe <i class="uil uil-arrow-right"></i></button>
                                            </form>
                                            <!--end form-->
                                          </div>
                                          <!--end subscribe form-->
                                        </div>
                                        <!--end col-->
                                      </div>
                                    <!--end row-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          
          </div>
        </div>
    </div>


    <!--end container-->

   
</section>
<!--end section-->
<!-- End -->

<!-- Back to top -->
<!-- 
<i-feather name="thumbs-up" class="icons"></i-feather>
<i-feather name="thumbs-down" class="icons"></i-feather> -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top"  id="back-to-top" [ngxScrollTo]="'#home'">


    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->