<!-- Hero Start -->
<section class="bg-half-100 bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> Frequently Asked Questions </h4>

          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb bg-white rounded shadow mb-0">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Help Center</a></li>
                    <li class="breadcrumb-item active" aria-current="page">FAQs</li>
                </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Start Section -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-5 col-12 d-none d-md-block">
          <div class="rounded shadow p-4 sticky-bar">
              <ul class="list-unstyled mb-0">
                  <li>
                      <a href="javascript: void(0);" [ngxScrollTo]="'#general'"
                         class="mouse-down h6 text-dark">
                          General
                          Questions
                      </a>
                  </li>
                  <li class="mt-4">
                      <a href="javascript: void(0);" [ngxScrollTo]="'#application'"
                         class="mouse-down h6 text-dark">
                          Application
                          Questions
                      </a>
                  </li>
                  <li class="mt-4">
                      <a href="javascript: void(0);" [ngxScrollTo]="'#support'"
                         class="mouse-down h6 text-dark">
                          Support
                          Questions
                      </a>
                  </li>
              </ul>
          </div>        
      </div>
      <!--end col-->

      <div class="col-lg-8 col-md-7 col-12">
        <div class="section-title" id="general">
          <h4>General Questions</h4>
        </div>

        <div class="faq-content mt-4 pt-3">

            <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panelfaq" closeOthers="true">
                <ngb-panel id="ngb-panelfaq" cardClass="border-0 rounded shadow mb-2">
                    <ng-template ngbPanelTitle>
                        <div class="border-0 bg-light" id="headingone">
                            <h6 class="title mb-0 text-dark"> What is <span class="text-primary">HSA-Shop</span>? </h6>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <p class="text-muted mb-0 faq-ans">
                            HSA-Shop is your one stop directory with over 7000+ of HSA-Eligible products available in savings in pre-tax.
                            <br/><br/>
                            <a href="hsa-shop#features" class="text-primary fw-bold">Learn more</a> about HSA-Shop.
                        </p>
                    </ng-template>
                </ngb-panel>
                <ngb-panel cardClass="border-0 rounded shadow mb-2">
                    <ng-template ngbPanelTitle>
                        <div class="border-0 bg-light" id="headingthree">
                            <h6 class="title mb-0 text-dark"> What is <span class="text-primary">Health Savings Account (HSA)</span>? </h6>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <p class="text-muted mb-0 faq-ans">
                            Health Savings Account is a type of savings account where you can deposit your pre-tax money to pay for qualified medical expenses.
                            <br/><br/>
                            <a href="hsa-shop#learnmore" class="text-primary fw-bold">Learn more</a> about HSA.
                        </p>
                    </ng-template>
                </ngb-panel>
                <ngb-panel cardClass="border-0 rounded shadow mb-2">
                    <ng-template ngbPanelTitle>
                        <div class="border-0 bg-light" id="headingfour">
                            <h6 class="title mb-0 text-dark"> What is <span class="text-primary">HSA-Eligible Products</span>? </h6>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <p class="text-muted mb-0 faq-ans">
                            HSA-Eligible Products are qualifying health products that you can purchase using your tax-free money from your Health Savings Accounts (HSA).
                            <br/><br/>
                            <a href="hsa-shop#learnmore" class="text-primary fw-bold">Learn more</a> about HSA-Eligible Products.
                        </p>
                    </ng-template>
                </ngb-panel>
                <ngb-panel cardClass="border-0 rounded shadow mb-2">
                    <ng-template ngbPanelTitle>
                        <div class="border-0 bg-light" id="headingfour">
                            <h6 class="title mb-0 text-dark"> Is HSA-Shop <span class="text-primary">affiliate</span> with anyone? </h6>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <p class="text-muted mb-0 faq-ans">
                            Amazon Services LLC Associates Program
                            <br /><br />
                            We are a participant in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for us to earn fees by linking to Amazon.com and affiliated sites.
                        </p>
                    </ng-template>
                </ngb-panel>
                <ngb-panel cardClass="border-0 rounded shadow mb-2">
                    <ng-template ngbPanelTitle>
                        <div class="border-0 bg-light" id="headingfour">
                            <h6 class="title mb-0 text-dark"> How can I <span class="text-primary">access</span> HSA-Shop HSA-Eligible product directory? </h6>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <p class="text-muted mb-0 faq-ans">
                            HSA-Shop product directory is accessible via iOS or Android mobile applications. 
                            <br /><br />
                            <a href="hsa-shop#product" class="text-primary fw-bold">Download</a> our HSA-Shop Mobile Application.
                        </p>
                    </ng-template>
                </ngb-panel>
                <ngb-panel cardClass="border-0 rounded shadow mb-2">
                    <ng-template ngbPanelTitle>
                        <div class="border-0 bg-light" id="headingfour">
                            <h6 class="title mb-0 text-dark"> How much does it <span class="text-primary">cost</span> to use HSA-Shop? </h6>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <p class="text-muted mb-0 faq-ans">
                            HSA-Shop is Free.
                            <br/><br/>
                            <a href="hsa-shop#pricing" class="text-primary fw-bold">Learn more</a> about HSA-Shop pricing details.
                        </p>
                    </ng-template>
                </ngb-panel>
                <ngb-panel cardClass="border-0 rounded shadow mb-2">
                    <ng-template ngbPanelTitle>
                        <div class="border-0 bg-light" id="headingtwo">
                            <h6 class="title mb-0 text-dark"> How do I <span class="text-primary">sign-up</span> for an account with HSA-Shop? </h6>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <p class="text-muted mb-0 faq-ans">
                            Sign-up is not required. You can get started by downloading our iOS or Android mobile application to start browsing our HSA-Eligible product directory.
                            <br/><br/>
                            <a href="hsa-shop#product" class="text-primary fw-bold">Download</a> our HSA-Shop Mobile Application.
                        </p>
                    </ng-template>
                </ngb-panel>
                <!-- <ngb-panel cardClass="border-0 rounded shadow mb-2">
                    <ng-template ngbPanelTitle>
                        <div class="border-0 bg-light" id="headingtwo">
                            <h6 class="title mb-0 text-dark"> I want to be <span class="text-primary">inform</span> when the price change on a HSA product? </h6>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <p class="text-muted mb-0 faq-ans">
                            There are many variations of passages of Lorem Ipsum
                            available, but
                            the majority have suffered alteration in some form.
                        </p>
                    </ng-template>
                </ngb-panel> -->
            </ngb-accordion>
        </div>

        <div class="section-title mt-5" id="application">
          <h4>Application Questions</h4>
        </div>

        <div class="faq-content mt-4 pt-3">
          <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panelapplication" closeOthers="true">
            <ngb-panel id="ngb-panelapplication" cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingone">
                  <h6 class="title mb-0 text-dark"> How do I <span class="text-primary">browse</span> HSA-Shop product directory? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                  <p class="text-muted mb-0 faq-ans">
                      Our product directory is accessible via iOS or Android mobile application.
                      <br/><br/>
                      <a href="hsa-shop#product" class="text-primary fw-bold">Download</a> our HSA-Shop Mobile Application.
                  </p>
              </ng-template>
            </ngb-panel>
            <!-- <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingtwo">
                  <h6 class="title mb-0 text-dark"> How do I <span class="text-primary">setup</span> pricing notification for my HSA product of interest? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum
                  available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel> -->
            <!-- <ngb-panel cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingthree">
                  <h6 class="title mb-0 text-dark"> I <span class="text-primary">do not</span> want to receive pricing notification on a certain HSA product anymore? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum
                  available, but
                  the majority have suffered alteration in some form.</p>
              </ng-template>
            </ngb-panel>             -->
          </ngb-accordion>
        </div>

        <div class="section-title mt-5" id="support">
          <h4>Support Questions</h4>
        </div>

        <div class="faq-content mt-4 pt-3">
          <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panelsupport" closeOthers="true">
            <ngb-panel id="ngb-panelsupport" cardClass="border-0 rounded shadow mb-2">
              <ng-template ngbPanelTitle>
                <div class="border-0 bg-light" id="headingone">
                    <h6 class="title mb-0 text-dark"> I need help/have <span class="text-primary">questions</span> related to HSA-Shop ? </h6>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p class="text-muted mb-0 faq-ans">
                  Our email is <a href="mailto:contactus@thinkdiscountllc.com" class="text-primary fw-bold">contactus@thinkdiscountllc.com</a>
                   <br/><br/>
                   Please reach out to us using the <a href="contact-us" class="text-primary fw-bold">Contact Us</a> form.
                </p>
              </ng-template>
            </ngb-panel>            
            
          </ngb-accordion>

        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-12">
        <div class="d-flex align-items-center features feature-clean shadow rounded p-4">
          <div class="icons text-primary text-center">
            <i class="uil uil-envelope-check d-block rounded h3 mb-0"></i>
          </div>
          <div class="flex-1 content ms-4">
            <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Have A Request ?</a></h5>
            <p class="text-muted mb-0">This is required when, for text is not yet available.</p>
            <div class="mt-2">
              <a href="contact-us" class="btn btn-soft-primary">Submit a Request</a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="d-flex align-items-center features feature-clean shadow rounded p-4">
          <div class="icons text-primary text-center">
            <i class="uil uil-question-circle d-block rounded h3 mb-0"></i>
          </div>
          <div class="flex-1 content ms-4">
            <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Questions ?</a></h5>
            <p class="text-muted mb-0">This is required when, for text is not yet available.</p>
            <div class="mt-2">
              <a href="contact-us" class="btn btn-soft-primary">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Section -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->