<!-- Hero Start -->
<section class="bg-half-100 bg-light d-table w-100" id="home"
  style="background: url('assets/images/contact-detail.jpg') center center;">
  <div class="bg-overlay bg-overlay-white"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title">Contact Us</h4>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">Resources</a></li>
                <li class="breadcrumb-item active" aria-current="page">Contact Us</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Contact -->
<section class="section">
  <div class="container">
      <div class="row">

          <div class="col-lg-5 col-md-6 mt-5 order-2 order-md-1">
              <div class="title-heading ms-lg-4">
                  <h4 class="mb-4">Contact Us</h4>
                  <p class="text-muted">
                      Inquiries? Please reach to us using the <span class="text-primary fw-bold">Contact Us Form</span>. We look forward to hearing from you.
                  </p>
                  <div class="d-flex contact-detail align-items-center mt-3">
                      <div class="icon">
                          <i-feather name="mail" class="fea icon-m-md text-dark me-3"></i-feather>
                      </div>
                      <div class="flex-1 content">
                          <h6 class="title fw-bold mb-0">Email</h6>
                          <a href="mailto:contactus@thinkdiscountllc.com" class="text-primary">contactus@thinkdiscountllc.com</a>
                      </div>
                  </div>

                  <div class="d-flex contact-detail align-items-center mt-3">
                      <div class="icon">
                          <i-feather name="map-pin" class="fea icon-m-md text-dark me-3"></i-feather>
                      </div>
                      <div class="flex-1 content">
                          <h6 class="title fw-bold mb-0">Location</h6>
                          <span>
                              Think Discount, LLC<br />
                              Washington<br />
                              United States of America
                          </span>
                      </div>
                  </div>
              </div>
          </div>
          <!--end col-->


          <div class="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-1 order-md-2">
              <div class="card shadow rounded border-0">
                  <div class="card-body py-5">
                      <h4 class="card-title">Get In Touch !</h4>
                      <div class="custom-form mt-3">
                              <p id="error-msg" class="mb-0"></p>
                              <div id="simple-msg"></div>
                              <div class="row">
                                  <div class="col-md-6">
                                      <div class="mb-3">
                                          <label class="form-label">Your Name <span class="text-danger">*</span></label>
                                          <div class="form-icon position-relative">
                                              <i-feather name="user" class="fea icon-sm icons"></i-feather>
                                              <input [(ngModel)]="name" name="name" id="name" type="text" class="form-control ps-5" placeholder="Name :">
                                          </div>
                                      </div>
                                  </div>

                                  <div class="col-md-6">
                                      <div class="mb-3">
                                          <label class="form-label">Your Email <span class="text-danger">*</span></label>
                                          <div class="form-icon position-relative">
                                              <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                              <input [(ngModel)]="email" name="email" id="email" type="email" class="form-control ps-5" placeholder="Email :">
                                          </div>
                                      </div>
                                  </div>
                                  <!--end col-->

                                  <div class="col-12">
                                      <div class="mb-3">
                                          <label class="form-label">Subject</label>
                                          <div class="form-icon position-relative">
                                              <i-feather name="book" class="fea icon-sm icons"></i-feather>
                                              <input [(ngModel)]="subject" name="subject" id="subject" class="form-control ps-5" placeholder="Subject :">
                                          </div>
                                      </div>
                                  </div>
                                  <!--end col-->

                                  <div class="col-12">
                                      <div class="mb-3">
                                          <label class="form-label">Comments <span class="text-danger">*</span></label>
                                          <div class="form-icon position-relative">
                                              <i-feather name="message-circle" class="fea icon-sm icons clearfix"></i-feather>
                                              <textarea [(ngModel)]="comments" name="comments" id="comments" rows="4" class="form-control ps-5"
                                                        placeholder="Message :"></textarea>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col-12">
                                      <div class="d-grid">
                                          <button (click)="submitContactForm()" id="submit" name="send" class="btn btn-primary">Send Message</button>
                                      </div>
                                  </div>
                                  <!--end col-->
                              </div>
                              <!--end row-->
                      </div>
                      <!--end custom-form-->
                  </div>
              </div>
          </div>
          <!--end col-->


      </div>
    <!--end row-->
  </div>
  <!--end container-->


</section>
<!--end section-->
<!-- End contact -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->