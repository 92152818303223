<div *ngIf="!hideFooter">
    <div *ngIf="footerVariant == 'bg-light';else execute_block">
        <!-- Footer Start -->
        <footer class="footer bg-light">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                        <a href="/" class="logo-footer">
                            <img src="assets/images/logo-dark.png" height="24" alt="HSA-Shop Logo">
                        </a>
                        <p class="mt-4 text-muted">
                            We are inspired to help educate people to make better choices on how they can save more money on their health expenses by making it easy for anyone to locate HSA-Eligible products.
                            <br /><br />
                            HSA-Shop is your one stop directory with over 7000+ of HSA-Eligible products available in savings in pre-tax.
                            <br /><br />
                            Download our application to browse our Health Savings Accounts (HSA) eligible products directory today!
                        </p>
                        <!-- <ul class="list-unstyled social-icon social mb-0 mt-4">
                            <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                                </a></li>o
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                    <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                                </a></li>
                        </ul> -->
                        <!--end icon-->
                    </div>
                    <!--end col-->

                    <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-dark footer-head">HSA Shop</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li>
                                <a routerLink="/hsa-shop" fragment="product" class="text-muted">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    Product
                                </a>
                            </li>
                            <li>
                                <a routerLink="/hsa-shop" fragment="features" class="text-muted">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    Features
                                </a>
                            </li>
                            <li>
                                <a routerLink="/hsa-shop" fragment="learnmore" class="text-muted">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    Learn More
                                </a>
                            </li>
                            <li>
                                <a routerLink="/hsa-shop" fragment="pricing" class="text-muted">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    Pricing
                                </a>
                            </li>
                            <li>
                                <a routerLink="/hsa-shop" fragment="downloads" class="text-muted">
                                    <i class="uil uil-angle-right-b me-1"></i> Downloads
                                </a>
                            </li>
                        </ul>
                        <br /><br />
                        <h5 class="text-dark footer-head">Directory</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li>
                                <a routerLink="/hsa-shop" fragment="product" class="text-muted">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    Tests
                                </a>
                            </li>
                            <li>
                                <a routerLink="/hsa-shop" fragment="features" class="text-muted">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    Prescriptions
                                </a>
                            </li>
                            <li>
                                <a routerLink="/hsa-shop" fragment="learnmore" class="text-muted">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    Services
                                </a>
                            </li>
                            <li>
                                <a routerLink="/hsa-shop" fragment="pricing" class="text-muted">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    Products
                                </a>
                            </li>                            
                        </ul>
                    </div>
                    <!--end col-->

                    <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-light footer-head">Help Center</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li>
                                <a routerLink="/faqs" class="text-muted">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    FAQs
                                </a>
                            </li>
                            <li>
                                <a routerLink="/contact-us" class="text-muted">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    Submit A Request
                                </a>
                            </li>
                        </ul>
                        <br /><br />
                        <h5 class="text-dark footer-head">Resources</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li>
                                <a routerLink="/about-us" class="text-muted">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    About Us
                                </a>
                            </li>
                            <li>
                                <a routerLink="/terms-of-use" class="text-muted">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    Terms of Use
                                </a>
                            </li>
                            <li>
                                <a routerLink="/privacy-policy" class="text-muted">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    Privacy Policy
                                </a>
                            </li>
                            <li>
                                <a routerLink="/contact-us" class="text-muted">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    Contact Us
                                </a>
                            </li>
                        </ul>
                    </div>
                    <!--end col-->


                    <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-light footer-head">Affiliation</h5>
                        <p class="mt-4">We are a participant in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for us to earn fees by linking to Amazon.com and affiliated sites.</p>
                        <br />
                        <h5 class="text-dark footer-head">For Business</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li>
                                <a routerLink="/hsa-shop" fragment="product" class="text-muted">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    Employee Benefits
                                </a>
                            </li>
                            <li>
                                <a routerLink="/hsa-shop" fragment="features" class="text-muted">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    How Do I Get Listed?
                                </a>
                            </li>
                            <li>
                                <a routerLink="/hsa-shop" fragment="learnmore" class="text-muted">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    Apply To Directory
                                </a>
                            </li>
                        </ul>
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
            <!--end container-->
        </footer>
        <!-- Footer End -->
    </div>
    <ng-template #execute_block>
        <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                        <a href="/" class="logo-footer">
                            <img src="assets/images/logo-light.png" alt="HSA-Shop Logo" height="24">
                        </a>
                        <p class="mt-4">
                            We are inspired to help educate people to make better choices on how they can save more money on their health expenses by making it easy for anyone to locate HSA-Eligible products.
                            <br /><br />
                            HSA-Shop is your one stop directory with over 7000+ of HSA-Eligible products available in savings in pre-tax.
                            <br /><br />
                            Download our application to browse our Health Savings Accounts (HSA) eligible products directory today!
                        </p>
                        <!-- <ul class="list-unstyled social-icon foot-social-icon mb-0 mt-4">
            <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                </a></li>
            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                </a></li>
            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                </a></li>
            <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                </a></li>
        </ul> -->
                        <!--end icon-->
                    </div>
                    <!--end col-->

                    <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-light footer-head">HSA Shop</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li>
                                <a routerLink="/hsa-shop" fragment="product" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;Product&nbsp;</span>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/hsa-shop" fragment="features" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;Features&nbsp;</span>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/hsa-shop" fragment="learnmore" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;Learn More&nbsp;</span>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/hsa-shop" fragment="pricing" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;Pricing&nbsp;</span>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/hsa-shop" fragment="downloads" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;Downloads&nbsp;</span>
                                </a>
                            </li>
                        </ul>
                        <br /><br />
                        <h5 class="text-light footer-head">Tests</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li>
                                <a routerLink="/directory/tests/std" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;STD&nbsp;</span>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/directory/tests/others" fragment="paternity" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;Paternity&nbsp;</span>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/directory/tests/others" fragment="arthritis" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;Arthritis&nbsp;</span>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/directory/tests/others" fragment="inflammation" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;Inflammation&nbsp;</span>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/directory/tests/others" fragment="hormone" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;Hormone&nbsp;</span>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/directory/tests/others" fragment="vitamin" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;Vitamin&nbsp;</span>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/directory/tests/others" fragment="nutritional" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;Nutritional&nbsp;</span>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/directory/tests/others" fragment="allergy" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;Allergy&nbsp;</span>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/directory/tests/others" fragment="gastrointestinal" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;Gastrointestinal&nbsp;</span>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/directory/tests/others" fragment="vegan" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;Vegan&nbsp;</span>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/directory/tests/others" fragment="drug" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;Drug&nbsp;</span>
                                </a>
                            </li>
                            <!--<li>
            <a routerLink="/hsa-shop" fragment="features" class="text-foot">
                <i class="uil uil-angle-right-b me-1"></i>
                <span class="bg-highlight">&nbsp;Prescriptions&nbsp;</span>
            </a>
        </li>
        <li>
            <a routerLink="/hsa-shop" fragment="learnmore" class="text-foot">
                <i class="uil uil-angle-right-b me-1"></i>
                <span class="bg-highlight">&nbsp;Services&nbsp;</span>
            </a>
        </li>
        <li>
            <a routerLink="/hsa-shop" fragment="pricing" class="text-foot">
                <i class="uil uil-angle-right-b me-1"></i>
                <span class="bg-highlight">&nbsp;Products&nbsp;</span>
            </a>
        </li>-->
                        </ul>
                        <br /><br />
                        <h5 class="text-light footer-head">Prescriptions</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li>
                                <a routerLink="/directory/prescriptions" fragment="glasses" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;Glasses&nbsp;</span>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/directory/prescriptions" fragment="contactlens" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;Contact Lens&nbsp;</span>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/directory/prescriptions" fragment="vitamin" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;Vitamins&nbsp;</span>
                                </a>
                            </li>
                        </ul>
                    </div>

                 
                    <!--end col-->

                    <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-light footer-head">Help Center</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li>
                                <a routerLink="/faqs" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;FAQs&nbsp;</span>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/contact-us" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;Submit A Request&nbsp;</span>
                                </a>
                            </li>
                        </ul>
                        <br /><br />
                        <h5 class="text-light footer-head">Resources</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li>
                                <a routerLink="/about-us" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;About Us&nbsp;</span>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/terms-of-use" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;Terms of Use&nbsp;</span>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/privacy-policy" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;Privacy Policy&nbsp;</span>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/contact-us" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;Contact Us&nbsp;</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <!--end col-->


                    <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h5 class="text-light footer-head">Affiliation</h5>
                        <p class="mt-4">We are a participant in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for us to earn fees by linking to Amazon.com and affiliated sites.</p>
                        <!--
                        <br/>
                        <h5 class="text-light footer-head">For Business</h5>
                        <ul class="list-unstyled footer-list mt-4">
                            <li>
                                <a routerLink="/hsa-shop" fragment="product" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;Employee Benefits&nbsp;</span>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/hsa-shop" fragment="features" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;How Do I Get Listed?&nbsp;</span>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/hsa-shop" fragment="learnmore" class="text-foot">
                                    <i class="uil uil-angle-right-b me-1"></i>
                                    <span class="bg-highlight">&nbsp;Apply To Directory&nbsp;</span>
                                </a>
                            </li>
                        </ul>
                        -->
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
            <!--end container-->
        </footer>
    </ng-template>
</div>
<!--end footer-->
<footer class="footer footer-bar">
    <div class="container text-center">
        <div class="row align-items-center">
            <div class="col-sm-6">
                <div class="text-sm-start">
                    <p class="mb-0">©
                        <script type="text/javascript" id="www-widgetapi-script"
                            src="https://www.youtube.com/s/player/5d56cf74/www-widgetapi.vflset/www-widgetapi.js"
                            async=""></script>
                        <script id="iframe_api" src="https://www.youtube.com/iframe_api"></script> {{year}}
                        HSA-Shop. Design with <i class="mdi mdi-heart text-danger"></i> by <a
                            href="javascript:void(0);" class="text-reset">Think Discount, LLC</a>.
                    </p>
                </div>
            </div>
            <!--end col-->

            <!--
            <div class="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <ul class="list-unstyled text-sm-end mb-0">
                    <li class="list-inline-item"><a href="javascript:void(0)"><img
                                src="assets/images/payments/american-ex.png" class="avatar avatar-ex-sm"
                                title="American Express" alt=""></a></li>
                    <li class="list-inline-item ms-1"><a href="javascript:void(0)"><img
                                src="assets/images/payments/discover.png" class="avatar avatar-ex-sm" title="Discover"
                                alt=""></a></li>
                    <li class="list-inline-item ms-1"><a href="javascript:void(0)"><img
                                src="assets/images/payments/master-card.png" class="avatar avatar-ex-sm"
                                title="Master Card" alt=""></a></li>
                    <li class="list-inline-item ms-1"><a href="javascript:void(0)"><img
                                src="assets/images/payments/paypal.png" class="avatar avatar-ex-sm" title="Paypal"
                                alt=""></a></li>
                    <li class="list-inline-item ms-1"><a href="javascript:void(0)"><img
                                src="assets/images/payments/visa.png" class="avatar avatar-ex-sm" title="Visa"
                                alt=""></a></li>
                </ul>
            </div>
                -->
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</footer>
<!--end footer-->