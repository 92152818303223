<section class="bg-half" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8 col-md-10">
          <div class="section-title">
              <div class="text-center">
                  <h4 class="title mb-4">{{ display.title }}</h4>
                  <img [src]="display.image" class="img-fluid rounded-md shadow-md" alt="">
              </div>
              <div *ngFor="let section of display.content">
                  <h4 class="my-4">{{ section.header }}</h4>
                  <p *ngFor="let paragraph of section.body" class="text-muted">
                      {{ paragraph }}
                  </p>
              </div>


              <!--
              <div class="row" id="counter">
                  <div class="col-md-4 col-6 mt-4 pt-2">
                      <div class="counter-box text-center">
                          <img src="assets/images/illustrator/Asset190.svg" class="avatar avatar-small" alt="">
                          <h2 class="mb-0 mt-4">
                              <span class="counter-value" [CountTo]="15" [from]="3" [duration]="4"></span>%
                          </h2>
                          <h6 class="counter-head text-muted">Depritiation</h6>
                      </div>
                      <!--end counter box--
                  </div>

                  <div class="col-md-4 col-6 mt-4 pt-2">
                      <div class="counter-box text-center">
                          <img src="assets/images/illustrator/Asset189.svg" class="avatar avatar-small" alt="">
                          <h2 class="mb-0 mt-4">
                              <span class="counter-value" [CountTo]="195" [from]="1" [duration]="4"></span>%
                          </h2>
                          <h6 class="counter-head text-muted">Profit</h6>
                      </div>
                      <!--end counter box--
                  </div>

                  <div class="col-md-4 col-6 mt-4 pt-2">
                      <div class="counter-box text-center">
                          <img src="assets/images/illustrator/Asset187.svg" class="avatar avatar-small" alt="">
                          <h2 class="mb-0 mt-4">
                              <span class="counter-value" [CountTo]="98" [from]="3" [duration]="4"></span>%
                          </h2>
                          <h6 class="counter-head text-muted">Case Solved</h6>
                      </div>
                      <!--end counter box--
                  </div>
              </div>
              <!--end row--
              
              <h4 class="my-4">Client Feedback</h4>
              <div class="p-4 bg-light">
                    <p class="text-muted h6 fst-italic">" It seems that only fragments of the original text remain in the
                      Lorem Ipsum texts used today. The most well-known dummy text is the 'Lorem Ipsum', which is said to have
                      originated in the 16th century. "</p>
                    <img src="assets/images/client/01.jpg" class="img-fluid avatar avatar-small rounded-circle mx-auto shadow"
                      alt="">
                    <ul class="list-unstyled mb-0 mt-3">
                      <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                      <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                      <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                      <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                      <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                    </ul>
                    <h6 class="text-primary">- Thomas Israel <small class="text-muted">C.E.O</small></h6>
                  </div> -->
            </div>
          </div>
        <!--end col-->
      </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->