<!-- Hero Start -->
<section class="bg-half-100 bg-light d-table w-100" style="background: url('assets/images/seo/bg.png') center center;"
    id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="title-heading">
                    <span class="badge rounded-pill bg-soft-primary"><b>For Your Business</b></span>
                    <h4 class="heading mt-2 mb-4">HSA-Shop Directory for Business</h4>
                    <p class="para-desc mx-auto text-muted mb-0">A business listing directory that connects consumers with a diverse array of HSA-Eligible products and services</p>

                    <div class="mt-4 pt-2">
                        <a routerLink="/business/directory" fragment="getstarted" class="btn btn-pills btn-primary m-1">Get Started</a>
                        <a routerLink="/business/directory" fragment="learnmore" class="btn btn-pills btn-soft-primary m-1">Learn More</a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--end section-->
<!-- Hero End -->

<!-- FEATURES START -->
<section class="section">
    <!--
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-11">
                <div class="features-absolute">
                    <div id="grid" class="row">
                        <ngx-masonry>
                            <div ngxMasonryItem class="spacing picture-item" *ngFor="let item of seoAgency">
                                <img src="{{item.images}}" class="img-fluid shadow seo-hero-widget rounded-md" alt="">
                            </div>
                            <!--end col--
                        </ngx-masonry>
                    </div>
                    <!--end row--
                </div>
                <!--end div--
            </div>
            <!--end col--
        </div>
        <!--end row--
    </div>-->
    <!--end container-->

    <!--<div class="container mt-100 mt-60">-->
    <div class="container mt-60" id="learnmore">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <span class="badge rounded-pill bg-soft-primary">Learn More</span>
                    <h4 class="title mt-3 mb-4">How HSA-Shop Directory Can <span class="text-primary">Benefit Your Business</span></h4>
                    <p class="text-muted para-desc mb-0 mx-auto">
                        Are you a <span class="text-primary fw-bold">business owner looking to expand the reach</span> of your health products and connect with a broader customer base? Are you a business that provides qualifying HSA-Eligible <span class="text-primary fw-bold">health services</span> or <span class="text-primary fw-bold">health products</span>?
                    </p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
        <app-services [servicesData]="servicesData"></app-services>
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div class="me-lg-5">
                    <img src="assets/images/illustrator/big-launch.svg" class="img-fluid" alt="">
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="section-title">
                    <h4 class="title mb-4">List Your <span class="text-primary">Health Products</span></h4>
                    <ul class="list-unstyled text-muted">
                        <li class="mb-0">
                            <span class="text-primary h5 me-2">
                                <i class="uil uil-check-circle align-middle"></i>
                            </span>Join our directory to show case your quality health products.
                        </li>
                        <li class="mb-0">
                            <span class="text-primary h5 me-2">
                                <i class="uil uil-check-circle align-middle"></i>
                            </span>Connect individuals with your healthcare products for their needs.
                        </li>
                        <li class="mb-0">
                            <span class="text-primary h5 me-2">
                                <i class="uil uil-check-circle align-middle"></i>
                            </span>Reach out to customers across the country.
                        </li>
                    </ul>
                    <div class="mt-4">
                        <a routerLink="/business/directory" fragment="getstarted" class="btn btn-pills btn-soft-primary">Get Started</a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->


    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-md-6 order-1 order-md-2">
                <div class="ms-lg-5">
                    <img src="assets/images/illustrator/maintenance.svg" class="img-fluid" alt="">
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 order-2 order-md-1 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="section-title">
                    <h4 class="title mb-4">List Your <span class="text-primary">Health Services</span></h4>
                    <ul class="list-unstyled text-muted">
                        <li class="mb-0">
                            <span class="text-primary h5 me-2">
                                <i class="uil uil-check-circle align-middle"></i>
                            </span>Become part of a thriving community of health service providers committed to improving lives in your community.
                        </li>
                        <li class="mb-0">
                            <span class="text-primary h5 me-2">
                                <i class="uil uil-check-circle align-middle"></i>
                            </span>Connect individuals with your healthcare services for their needs.
                        </li>
                        <li class="mb-0">
                            <span class="text-primary h5 me-2">
                                <i class="uil uil-check-circle align-middle"></i>
                            </span>Reach out to local customers in the area.
                        </li>
                    </ul>
                    <div class="mt-4">
                        <a routerLink="/business/directory" fragment="getstarted" class="btn btn-pills btn-soft-primary">Get Started</a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->


    <div class="container-fluid mt-100 mt-60" id="getstarted">
        <div class="bg-primary rounded shadow py-5" style="background: url('assets/images/seo/cta.png') center center;">
            <div class="container my-md-5">
                <div class="row">
                    <div class="col-12 text-center">
                        <div class="section-title">
                            <h1 class="text-white">Calling For Early Birds! Get Listed Today.</h1>
                            <h4 class="title text-white title-dark mb-4">Contact Us About HSA-Shop Business Directory</h4>
                            <p class="text-white-50 para-desc mb-0 mx-auto">
                                Join our HSA-Shop Business Directory today! Don't miss out on this chance to stand out in the market and drive your success to new heights.
                            </p>
                            <div class="business-signup-form mt-4 pt-2">
                                <form>
                                    <div class="mb-0">
                                        <div class="row form-bottom">
                                            <div class="col-md-12">
                                                <label class="form-label"><span class="text-white">Business Name</span>&nbsp;<span class="text-danger">*</span></label>
                                                <div class="form-icon position-relative">
                                                    <i-feather name="shopping-bag" class="fea icon-sm icons"></i-feather>
                                                    <input [(ngModel)]="businessname" name="businessname" id="businessname" type="text" class="form-control ps-5 rounded-lg" placeholder="YourHealthBusinessName">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row form-bottom">
                                            <div class="col-md-12">
                                                <label class="form-label"><span class="text-white">Contact Name</span>&nbsp;<span class="text-danger">*</span></label>
                                                <div class="form-icon position-relative">
                                                    <i-feather name="user" class="fea icon-sm icons"></i-feather>
                                                    <input [(ngModel)]="contactname" name="contactname" id="contactname" type="text" class="form-control ps-5 rounded-lg" placeholder="Firstname Lastname">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row form-bottom">
                                            <div class="col-md-6">
                                                <label class="form-label"><span class="text-white">Email</span>&nbsp;<span class="text-danger">*</span></label>
                                                <div class="form-icon position-relative">
                                                    <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                                    <input [(ngModel)]="email" name="email" id="email" type="text" class="form-control ps-5 rounded-lg" placeholder="contact@YourHealthBusiness.com">
                                                </div>
                                            </div>
                                            <div class="col-md-6 form-bottom">
                                                <label class="form-label"><span class="text-white">Contact Number</span>&nbsp;<span class="text-danger">*</span></label>
                                                <div class="form-icon position-relative">
                                                    <i-feather name="phone" class="fea icon-sm icons"></i-feather>
                                                    <input [(ngModel)]="phone" name="phone" id="phone" type="text" class="form-control ps-5 rounded-lg" placeholder="XXX-XXX-XXXX">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 form-bottom">
                                                <label class="form-label"><span class="text-white">Website</span></label>
                                                <div class="form-icon position-relative">
                                                    <i-feather name="globe" class="fea icon-sm icons"></i-feather>
                                                    <input [(ngModel)]="website" name="website" id="website" type="text" class="form-control ps-5 rounded-lg" placeholder="https://www.yourhealthbusiness.com">
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 form-bottom">
                                        <div class="mb-3">
                                            <label class="form-label"><span class="text-white">Product(s) or Service(s) Details</span>&nbsp;<span class="text-danger">*</span></label>
                                            <div class="form-icon position-relative">
                                                <i-feather name="message-circle" class="fea icon-sm icons clearfix"></i-feather>
                                                <textarea [(ngModel)]="listing" name="listing" id="listing" rows="6" class="form-control ps-5 rounded-lg"
                                                          placeholder="Tell us more about your product(s) or service(s) what you want to list in HSA-Shop Business Directory"></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <button (click)="submitBusinessDirectoryForm()" class="btn btn-pills btn-info">Get Started</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
            <!--end container-->
        </div>
        <!--end div-->
    </div>
    <!--end container-->
    
    <!--
    <div class="container mt-100 mt-60">
        <div class="row mt-lg-4 align-items-center">
            <div class="col-lg-5 col-md-12 text-center text-lg-start">
                <div class="section-title mb-4 mb-lg-0 pb-2 pb-lg-0">
                    <h4 class="title mb-4">Directory Listing Rates</h4>
                    <p class="text-muted para-desc mx-auto mb-0">
                        Start working with <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.
                    </p>
                    <ul class="nav nav-pills rounded-pill justify-content-center d-inline-block border py-1 px-2 mt-4 bg-white"
                        id="pills-tab" role="tablist">
                        <li class="nav-item d-inline-block">
                            <a class="nav-link px-3 rounded-pill monthly" id="Monthly" data-bs-toggle="pill"
                               (click)="changeTab('month')" href="javascript:void(0);"
                               [ngClass]="{'active' : isActive === 'month' }" role="tab" aria-controls="Month"
                               aria-selected="true">Monthly</a>
                        </li>
                        <li class="nav-item d-inline-block">
                            <a class="nav-link px-3 rounded-pill yearly" id="Yearly" data-bs-toggle="pill"
                               (click)="changeTab('year')" role="tab" href="javascript:void(0);"
                               [ngClass]="{'active' : isActive === 'year' }" aria-controls="Year"
                               aria-selected="false">Yearly</a>
                        </li>
                    </ul>
                </div>
            </div>
            <!--end col--

            <div class="col-lg-7 col-md-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="tab-content" id="pills-tabContent">

                    <div class="row align-items-center" *ngIf="isActive == 'month';else year_view">
                        <div class="col-md-6 col-12 px-md-0">
                            <div class="card pricing-rates starter-plan shadow rounded border-0">
                                <div class="card-body py-5">
                                    <h5 class="title fw-bold text-primary mb-4">Basic</h5>
                                    <div class="d-flex mb-4">
                                        <span class="h5 mb-0 mt-2">$</span>
                                        <span class="price h1 mb-0">39</span>
                                        <span class="h5 align-self-end mb-1">/mo</span>
                                    </div>

                                    <ul class="list-unstyled mb-0 ps-0">
                                        <li class="h6 text-muted mb-0">
                                            <span class="text-primary h5 me-2">
                                                <i class="uil uil-check-circle align-middle"></i>
                                            </span>Full
                                            Access
                                        </li>
                                        <li class="h6 text-muted mb-0">
                                            <span class="text-primary h5 me-2">
                                                <i class="uil uil-check-circle align-middle"></i>
                                            </span>Source
                                            Files
                                        </li>
                                        <li class="h6 text-muted mb-0">
                                            <span class="text-primary h5 me-2">
                                                <i class="uil uil-check-circle align-middle"></i>
                                            </span>Free
                                            Appointments
                                        </li>
                                        <li class="h6 text-muted mb-0">
                                            <span class="text-primary h5 me-2">
                                                <i class="uil uil-check-circle align-middle"></i>
                                            </span>Enhanced
                                            Security
                                        </li>
                                    </ul>
                                    <a href="javascript:void(0)" class="btn btn-primary mt-4">
                                        Get
                                        Started
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!--end col--

                        <div class="col-md-6 col-12 mt-4 pt-2 pt-sm-0 mt-sm-0 px-md-0">
                            <div class="card pricing-rates bg-light shadow rounded border-0">
                                <div class="ribbon ribbon-right ribbon-warning overflow-hidden">
                                    <span class="text-center d-block shadow small h6">Best</span>
                                </div>
                                <div class="card-body py-5">
                                    <h5 class="title fw-bold text-primary mb-4">Premium</h5>
                                    <div class="d-flex mb-4">
                                        <span class="h5 mb-0 mt-2">$</span>
                                        <span class="price h1 mb-0">59</span>
                                        <span class="h5 align-self-end mb-1">/mo</span>
                                    </div>

                                    <ul class="list-unstyled mb-0 ps-0">
                                        <li class="h6 text-muted mb-0">
                                            <span class="text-primary h5 me-2">
                                                <i class="uil uil-check-circle align-middle"></i>
                                            </span>Full
                                            Access
                                        </li>
                                        <li class="h6 text-muted mb-0">
                                            <span class="text-primary h5 me-2">
                                                <i class="uil uil-check-circle align-middle"></i>
                                            </span>Source
                                            Files
                                        </li>
                                        <li class="h6 text-muted mb-0">
                                            <span class="text-primary h5 me-2">
                                                <i class="uil uil-check-circle align-middle"></i>
                                            </span>Free
                                            Appointments
                                        </li>
                                        <li class="h6 text-muted mb-0">
                                            <span class="text-primary h5 me-2">
                                                <i class="uil uil-check-circle align-middle"></i>
                                            </span>Enhanced
                                            Security
                                        </li>
                                    </ul>
                                    <a href="javascript:void(0)" class="btn btn-primary mt-4">Try it now</a>
                                </div>
                            </div>
                        </div>
                        <!--end col--
                    </div>
                    <ng-template #year_view>
                        <div class="row align-items-center">
                            <div class="col-md-6 col-12 px-md-0">
                                <div class="card pricing-rates starter-plan shadow rounded border-0">
                                    <div class="card-body py-5">
                                        <h5 class="title fw-bold text-primary mb-4">Basic</h5>
                                        <div class="d-flex mb-4">
                                            <span class="h5 mb-0 mt-2">$</span>
                                            <span class="price h1 mb-0">29</span>
                                            <span class="h5 align-self-end mb-1">/mo</span>
                                        </div>

                                        <ul class="list-unstyled mb-0 ps-0">
                                            <li class="h6 text-muted mb-0">
                                                <span class="text-primary h5 me-2">
                                                    <i class="uil uil-check-circle align-middle"></i>
                                                </span>Full
                                                Access
                                            </li>
                                            <li class="h6 text-muted mb-0">
                                                <span class="text-primary h5 me-2">
                                                    <i class="uil uil-check-circle align-middle"></i>
                                                </span>Source
                                                Files
                                            </li>
                                            <li class="h6 text-muted mb-0">
                                                <span class="text-primary h5 me-2">
                                                    <i class="uil uil-check-circle align-middle"></i>
                                                </span>Free
                                                Appointments
                                            </li>
                                            <li class="h6 text-muted mb-0">
                                                <span class="text-primary h5 me-2">
                                                    <i class="uil uil-check-circle align-middle"></i>
                                                </span>Enhanced
                                                Security
                                            </li>
                                        </ul>
                                        <a href="javascript:void(0)" class="btn btn-primary mt-4">
                                            Get
                                            Started
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <!--end col--

                            <div class="col-md-6 col-12 mt-4 pt-2 pt-sm-0 mt-sm-0 px-md-0">
                                <div class="card pricing-rates bg-light shadow rounded border-0">
                                    <div class="ribbon ribbon-right ribbon-warning overflow-hidden">
                                        <span class="text-center d-block shadow small h6">Popular</span>
                                    </div>
                                    <div class="card-body py-5">
                                        <h5 class="title fw-bold text-primary mb-4">Premium</h5>
                                        <div class="d-flex mb-4">
                                            <span class="h5 mb-0 mt-2">$</span>
                                            <span class="price h1 mb-0">49</span>
                                            <span class="h5 align-self-end mb-1">/mo</span>
                                        </div>

                                        <ul class="list-unstyled mb-0 ps-0">
                                            <li class="h6 text-muted mb-0">
                                                <span class="text-primary h5 me-2">
                                                    <i class="uil uil-check-circle align-middle"></i>
                                                </span>Full
                                                Access
                                            </li>
                                            <li class="h6 text-muted mb-0">
                                                <span class="text-primary h5 me-2">
                                                    <i class="uil uil-check-circle align-middle"></i>
                                                </span>Source
                                                Files
                                            </li>
                                            <li class="h6 text-muted mb-0">
                                                <span class="text-primary h5 me-2">
                                                    <i class="uil uil-check-circle align-middle"></i>
                                                </span>Free
                                                Appointments
                                            </li>
                                            <li class="h6 text-muted mb-0">
                                                <span class="text-primary h5 me-2">
                                                    <i class="uil uil-check-circle align-middle"></i>
                                                </span>Enhanced
                                                Security
                                            </li>
                                        </ul>
                                        <a href="javascript:void(0)" class="btn btn-primary mt-4">Try it now</a>
                                    </div>
                                </div>
                            </div>
                            <!--end col--
                        </div>
                    </ng-template>
                </div>
            </div>
            <!--end col--
        </div>
        <!--end row--
    </div>
    -->
    <!--end container-->
    <!--
    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">Client's Review</h4>
                    <p class="text-muted para-desc mx-auto mb-0">
                        Start working with <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.
                    </p>
                </div>
            </div>
            <!--end col--
    </div>
    <!--end row--

    <app-testimonial [testimonialData]="testimonialData"></app-testimonial>
    </div>
    -->
    <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->