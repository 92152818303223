<!-- Hero Start -->
<section class="bg-half-100 bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="page-next-level">
                    <h4 class="title"> Terms of Use </h4>
                    <ul class="list-unstyled mt-4">
                        <li class="list-inline-item h6 date text-muted">
                            <span class="text-dark">Last Revised :</span> September 3rd,
                            2022
                        </li>
                    </ul>
                    <div class="page-next">
                        <nav aria-label="breadcrumb" class="d-inline-block">
                            <ul class="breadcrumb bg-white rounded shadow mb-0">
                                <li class="breadcrumb-item"><a href="javascript:void(0)">Resources</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Terms Of Use</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->
<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->
<!-- Start Terms & Conditions -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-9">
                <div class="card shadow border-0 rounded">
                    <div class="card-body">
                        <h3 class="card-title text-primary">Introduction</h3>
                        <div class="col-md-12 col-md-offset-1">
                            <p>
                                Please read these terms of use carefully before you start to
                                use the site. By using our site, you indicate that you accept these terms of use
                                and that you agree to abide by them. If you do not agree to these terms of use,
                                please refrain from using our site.
                            </p>
                            <h3 class="text-primary">Reliance On Information Posted &amp; Disclaimer</h3>
                            <p>
                                The materials contained on our site or applications are provided for general
                                information purposes only and do not claim to be or constitute legal or other
                                professional advice and shall not be relied upon as such.
                            </p>
                            <p>
                                We do not accept any responsibility for any loss which may
                                arise from accessing or reliance on the information on this site and to the
                                fullest extent permitted by United States law, we exclude all liability for loss or damages
                                direct or
                                indirect arising from use of this site.
                            </p>

                            <h3 class="text-primary">Information about us</h3>
                            <p>
                                www.hsa-shop.net is a site operated by Think Discount, LLC
                                ("We"); registered in 
                                Washington.
                            </p>
                            <h3 class="text-primary">Accessing our site</h3>
                            <p>
                                Access to our site is permitted on a temporary basis, and we
                                reserve the right to withdraw or amend the service we provide on our site
                                without notice (see below). We will not be liable if for any reason our site is
                                unavailable at any time or for any period.
                            </p>
                            <h3 class="text-primary">Intellectual property rights</h3>
                            <p>
                                We are the owner or the licensee of all intellectual property
                                rights in our site, and in the material published on it. Those works are
                                protected by copyright laws and treaties around the world. All such rights
                                are reserved.
                            </p>
                            <p>
                                You may print off one copy, and may download extracts, of any
                                page(s) from our site for your personal reference and you may draw the attention
                                of others within your organization to material posted on our site.
                            </p>
                            <p>
                                You must not modify the paper or digital copies of any
                                materials you have printed off or downloaded in any way, and you must not use
                                any illustrations, photographs, video or audio sequences or any graphics
                                separately from any accompanying text.
                            </p>
                            <p>
                                Our status (and that of any identified contributors) as the
                                authors of material on our site must always be acknowledged.
                            </p>
                            <p>
                                You must not use any part of the materials on our site for
                                commercial purposes without obtaining a licence to do so from us or our
                                licensors.
                            </p>
                            <p>
                                If you print off, copy or download any part of our site in
                                breach of these terms of use, your right to use our site will cease immediately
                                and you must, at our option, return or destroy any copies of the materials you
                                have made.
                            </p>
                            <h3 class="text-primary">Our site changes regularly</h3>
                            <p>
                                We aim to update our site regularly, and may change the
                                content at any time. If the need arises, we may suspend access to our site, or
                                close it indefinitely. Any of the material on our site may be out of date at any
                                given time, and we are under no obligation to update such material.
                            </p>
                            <h3 class="text-primary">Our liability</h3>
                            <p>
                                The material displayed on our site is provided without any
                                guarantees, conditions or warranties as to its accuracy. To the extent permitted
                                by law, we, and third parties connected to us hereby expressly exclude:
                            </p>
                            <p>
                            </p><ul class="list2">
                                <li>
                                    All conditions, warranties and other terms which might otherwise be implied by
                                    statute, common law or the law of equity.
                                </li>
                                <li>
                                    Any liability for any direct, indirect or consequential loss or damage incurred by
                                    any user in connection with our site or in connection with the use, inability to
                                    use, or results of the use of our site, any websites linked to it and any
                                    materials posted on it, including, without limitation any liability for:
                                    <ul class="list3">
                                        <li>loss of income or revenue;</li>
                                        <li>loss of business;</li>
                                        <li>loss of profits or contracts;</li>
                                        <li>loss of anticipated savings;</li>
                                        <li>loss of data;</li>
                                        <li>loss of goodwill;</li>
                                        <li>wasted management or office time; and</li>
                                        <li>
                                            for any other loss or damage of any kind, however arising and
                                            whether caused by tort (including negligence), breach of contract or
                                            otherwise,
                                            even if foreseeable, provided that this condition shall not prevent claims
                                            for
                                            loss of or damage to your tangible property or any other claims for direct
                                            financial loss that are not excluded by any of the categories set out above.
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <p></p>
                            <p>
                                This does not affect our liability for death or personal
                                injury arising from our negligence, nor our liability for fraudulent
                                misrepresentation or misrepresentation as to a fundamental matter, nor any other
                                liability which cannot be excluded or limited under applicable law.
                            </p>
                            <h3 class="text-primary">Information about you and your visits to our site</h3>
                            <p>
                                We process information about you in accordance with our
                                privacy policy [https://www.hsa-shop.net/privacy-policy] By using our site, you
                                consent to such processing and you warrant that all data provided by you is
                                accurate.
                            </p>
                            <h3 class="text-primary">Viruses, hacking and other offenses</h3>
                            <p>
                                You must not misuse our site by knowingly introducing
                                viruses, trojans, worms, logic bombs or other material which is malicious or
                                technologically harmful. You must not attempt to gain unauthorised access to our
                                site, the server on which our site is stored or any server, computer or database
                                connected to our site. You must not attack our site via a denial-of-service
                                attack or a distributed denial-of service attack.
                            </p>
                            <p>
                                By breaching this provision, you would commit a criminal
                                offence under the Computer Misuse Act 1990. We will report any such breach to
                                the relevant law enforcement authorities and we will co-operate with those
                                authorities by disclosing your identity to them. In the event of such a breach,
                                your right to use our site will cease immediately.
                            </p>
                            <p>
                                We will not be liable for any loss or damage caused by a
                                distributed denial-of-service attack, viruses or other technologically harmful
                                material that may infect your computer equipment, computer programs, data or
                                other proprietary material due to your use of our site or to your downloading of
                                any material posted on it, or on any website linked to it.
                            </p>

                            <h3 class="text-primary">Jurisdiction and applicable law</h3>
                            <p>
                                The US courts will have
                                non-exclusive jurisdiction over any claim arising from, or related to, a visit
                                to our site.
                            </p>
                            <p>
                                These terms of use and any dispute or claim arising out of or
                                in connection with them or their subject matter or formation (including
                                non-contractual disputes or claims) shall be governed by and construed in
                                accordance with the law of United States of America.
                            </p>
                            <h3 class="text-primary">Technology</h3>
                            <h5 class="text-dark">
                                Website and Mobile Applications
                            </h5>
                            <p>
                                We host our solutions in Microsoft Azure. We run our service offerings using Serverless technologies.
                            </p>
                            <p>
                                Our mobile application notification is powered by Google Firebase.
                            </p>

                            <h3 class="text-primary">Interpretation and Definitions</h3>
                            <h5 class="text-dark">Interpretation</h5>
                            <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
                            <h5 class="text-dark">Definitions</h5>
                            <p>For the purposes of these Terms of Use:</p>
                            <ul>
                                <li>
                                    <p><strong>Application</strong> means the software program provided by the Company downloaded by You on any electronic device, named HSA-Shop</p>
                                </li>
                                <li>
                                    <p><strong>Application Store</strong> means the digital distribution service operated and developed by Apple Inc. (Apple App Store), Google Inc. (Google Play Store) or Microsoft Corp. (Microsoft AppSource) in which the Application has been downloaded.</p>
                                </li>
                                <li>
                                    <p><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</p>
                                </li>
                                <li>
                                    <p><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</p>
                                </li>
                                <li>
                                    <p><strong>Country</strong> refers to: Washington, United States</p>
                                </li>
                                <li>
                                    <p><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Think Discount, LLC.</p>
                                </li>
                                <li>
                                    <p><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p>
                                </li>
                                <li>
                                    <p><strong>Feedback</strong> means feedback, innovations or suggestions sent by You regarding the attributes, performance or features of our Service.</p>
                                </li>
                                <li>
                                    <p><strong>Free Trial</strong> refers to a limited period of time that may be free when purchasing a Subscription.</p>
                                </li>
                                <li>
                                    <p><strong>Service</strong> refers to the Application.</p>
                                </li>
                                <li>
                                    <p><strong>Subscriptions</strong> refer to the services or access to the Service offered on a subscription basis by the Company to You.</p>
                                </li>
                                <li>
                                    <p><strong>Terms of Use</strong> (also referred as "Terms") mean these Terms of Use that form the entire agreement between You and the Company regarding the use of the Service.</p>
                                </li>
                                <li>
                                    <p><strong>Third-party Social Media Service</strong> means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.</p>
                                </li>
                                <li>
                                    <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
                                </li>
                            </ul>
                            <h3 class="text-primary">Acknowledgment</h3>
                            <p>These are the Terms of Use governing the use of this Service and the agreement that operates between You and the Company. These Terms of Use set out the rights and obligations of all users regarding the use of the Service.</p>
                            <p>Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms of Use. These Terms of Use apply to all visitors, users and others who access or use the Service.</p>
                            <p>By accessing or using the Service You agree to be bound by these Terms of Use. If You disagree with any part of these Terms of Use then You may not access the Service.</p>
                            <p>You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.</p>
                            <p>Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.</p>
                            <h3 class="text-primary">Subscriptions</h3>
                            <h5 class="text-dark">Subscription period</h5>
                            <p>The Service or some parts of the Service are available only with a paid Subscription. You will be billed in advance on a recurring and periodic basis (such as daily, weekly, monthly or annually), depending on the type of Subscription plan you select when purchasing the Subscription.</p>
                            <p>At the end of each period, Your Subscription will automatically renew under the exact same conditions unless You cancel it or the Company cancels it.</p>
                            <h5 class="text-dark">Subscription cancellations</h5>
                            <p>
                                You may cancel Your Subscription renewal by contacting the Company.
                                You will not receive a refund for the fees You already paid for Your current Subscription period and You will be able to access the Service until the end of Your current Subscription period.
                            </p>
                            <h5 class="text-dark">Billing</h5>
                            <p>You shall provide the Company with accurate and complete billing information including full name, address, state, zip code, telephone number, and a valid payment method information.</p>
                            <p>Should automatic billing fail to occur for any reason, the Company will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice.</p>
                            <h5 class="text-dark">Fee Changes</h5>
                            <p>The Company, in its sole discretion and at any time, may modify the Subscription fees. Any Subscription fee change will become effective at the end of the then-current Subscription period.</p>
                            <p>The Company will provide You with reasonable prior notice of any change in Subscription fees to give You an opportunity to terminate Your Subscription before such change becomes effective.</p>
                            <p>Your continued use of the Service after the Subscription fee change comes into effect constitutes Your agreement to pay the modified Subscription fee amount.</p>
                            <h5 class="text-dark">Refunds</h5>
                            <p>Except when required by law, paid Subscription fees are non-refundable.</p>
                            <p>Certain refund requests for Subscriptions may be considered by the Company on a case-by-case basis and granted at the sole discretion of the Company.</p>
                            <h5 class="text-dark">Free Trial</h5>
                            <p>The Company may, at its sole discretion, offer a Subscription with a Free Trial for a limited period of time.</p>
                            <p>You may be required to enter Your billing information in order to sign up for the Free Trial.</p>
                            <p>If You do enter Your billing information when signing up for a Free Trial, You will not be charged by the Company until the Free Trial has expired. On the last day of the Free Trial period, unless You cancelled Your Subscription, You will be automatically charged the applicable Subscription fees for the type of Subscription You have selected.</p>
                            <p>At any time and without notice, the Company reserves the right to (i) modify the terms of use of the Free Trial offer, or (ii) cancel such Free Trial offer.</p>
                            <h3 class="text-primary">User Accounts</h3>
                            <p>When You create an account with Us, You must provide Us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of Your account on Our Service.</p>
                            <p>You are responsible for safeguarding the password that You use to access the Service and for any activities or actions under Your password, whether Your password is with Our Service or a Third-Party Social Media Service.</p>
                            <p>You agree not to disclose Your password to any third party. You must notify Us immediately upon becoming aware of any breach of security or unauthorized use of Your account.</p>
                            <p>You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than You without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.</p>
                            <h3 class="text-primary">Your Feedback to Us</h3>
                            <p>You assign all rights, title and interest in any Feedback You provide the Company. If for any reason such assignment is ineffective, You agree to grant the Company a non-exclusive, perpetual, irrevocable, royalty free, worldwide right and license to use, reproduce, disclose, sub-license, distribute, modify and exploit such Feedback without restriction.</p>
                            <h3 class="text-primary">Links to Other Websites</h3>
                            <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.</p>
                            <p>The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>
                            <p>We strongly advise You to read the terms of use and privacy policies of any third-party web sites or services that You visit.</p>
                            <h3 class="text-primary">Termination</h3>
                            <p>We may terminate or suspend Your Account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms of Use.</p>
                            <p>Upon termination, Your right to use the Service will cease immediately. If You wish to terminate Your Account, You may simply discontinue using the Service.</p>
                            <h3 class="text-primary">Limitation of Liability</h3>
                            <p>Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of this Terms of Use and Your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You through the Service or 100 USD if You haven't purchased anything through the Service.</p>
                            <p>To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.</p>
                            <p>Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these states, each party's liability will be limited to the greatest extent permitted by law.</p>
                            <h3 class="text-primary">"AS IS" and "AS AVAILABLE" Disclaimer</h3>
                            <p>The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.</p>
                            <p>Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.</p>
                            <p>Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.</p>
                            <h3 class="text-primary">Governing Law</h3>
                            <p>The laws of the Country, excluding its conflicts of law rules, shall govern this Terms of Use and Your use of the Service. Your use of the Application may also be subject to other local, state, national, or international laws.</p>
                            <h3 class="text-primary">Disputes Resolution</h3>
                            <p>If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company.</p>
                            <h3 class="text-primary">For European Union (EU) Users</h3>
                            <p>If You are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in which you are resident in.</p>
                            <h3 class="text-primary">United States Federal Government End Use Provisions</h3>
                            <p>If You are a U.S. federal government end user, our Service is a "Commercial Item" as that term is defined at 48 C.F.R. &sect;2.101.</p>
                            <h3 class="text-primary">United States Legal Compliance</h3>
                            <p>You represent and warrant that (i) You are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a "terrorist supporting" country, and (ii) You are not listed on any United States government list of prohibited or restricted parties.</p>
                            <h3 class="text-primary">Severability and Waiver</h3>
                            <h5 class="text-dark">Severability</h5>
                            <p>If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.</p>
                            <h5 class="text-dark">Waiver</h5>
                            <p>Except as provided herein, the failure to exercise a right or to require performance of an obligation under this Terms shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall be the waiver of a breach constitute a waiver of any subsequent breach.</p>
                            <h3 class="text-primary">Translation Interpretation</h3>
                            <p>
                                These Terms of Use may have been translated if We have made them available to You on our Service.
                                You agree that the original English text shall prevail in the case of a dispute.
                            </p>
                            <h3 class="text-primary">Changes to These Terms of Use</h3>
                            <p>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.</p>
                            <p>You are expected to check this page from time to time to take notice of any changes we made, as they are binding on you. Some of the provisions contained in these terms of use may also be superseded by provisions or notices published elsewhere on our site.</p>
                            <p>By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.</p>

                            <h3 class="text-primary">Your concerns</h3>
                            <p>
                                If you have any concerns about material which appears on our
                                site, please contact <a href="contact-us" class="text-primary fw-bold">contactus@thinkdiscountllc.com</a>
                            </p>
                            <p>Thank you for visiting our site.</p>
                            <a href="javascript:window.print()" class="btn btn-soft-primary d-print-none">Print</a>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End Terms & Conditions -->
<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->