<!-- Hero Start -->
<section class="bg-half-120 bg-light d-table w-100" style="background: url('assets/images/seo/bg.png') center center;"
    id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="title-heading">
                    <h4 class="heading mt-2 mb-2">HSA Eligible</h4>
                    <h5 class="heading mt-2 mb-4 text-primary">Various Qualifying Tests</h5>
                    <p class="para-desc mx-auto text-muted mb-0">Learn more about HSA qualifing tests, browse HSA-Shop Directory today.</p>

                    <div class="mt-4 mb-4">
                        <a href="https://apps.apple.com/us/app/hsa/id1643850395" target="_blank" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
                        <a href="https://play.google.com/store/apps/details?id=com.thinkdiscountllc.hsa" target="_blank" class="btn btn-outline-primary mt-2">
                            <i class="uil uil-google-play"></i> Play
                            Store
                        </a>
                    </div>                   
                </div>
            </div>
            <!--end col-->

        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--end section-->
<!-- Hero End -->

<!-- FEATURES START -->
<section class="section">

    <div class="container mt-60 mb-20">
        <div class="row align-items-center">
            <div class="col-md-12 text-center">
                <a routerLink="/directory/tests/others" fragment="paternity" class="btn btn-pills btn-soft-primary mt-1"> Paternity </a>
                &nbsp;
                <a routerLink="/directory/tests/others" fragment="arthritis" class="btn btn-pills btn-soft-primary mt-1"> Arthritis </a>
                &nbsp;
                <a routerLink="/directory/tests/others" fragment="inflammation" class="btn btn-pills btn-soft-primary mt-1"> Inflammation </a>
                &nbsp;
                <a routerLink="/directory/tests/others" fragment="hormone" class="btn btn-pills btn-soft-primary mt-1"> Hormone </a>
                &nbsp;
                <a routerLink="/directory/tests/others" fragment="vitamin" class="btn btn-pills btn-soft-primary mt-1"> Vitamin </a>
                &nbsp;
                <a routerLink="/directory/tests/others" fragment="nutritional" class="btn btn-pills btn-soft-primary mt-1"> Nutritional </a>
                &nbsp;
                <a routerLink="/directory/tests/others" fragment="allergy" class="btn btn-pills btn-soft-primary mt-1"> Allergy </a>
                &nbsp;
                <a routerLink="/directory/tests/others" fragment="gastrointestinal" class="btn btn-pills btn-soft-primary mt-1"> Gastrointestinal </a>
                &nbsp;
                <a routerLink="/directory/tests/others" fragment="vegan" class="btn btn-pills btn-soft-primary mt-1"> Vegan </a>
                &nbsp;
                <a routerLink="/directory/tests/others" fragment="drug" class="btn btn-pills btn-soft-primary mt-1"> Drug </a>
            </div>
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60" id="paternity">
        <div class="row align-items-center">
            <div class="col-md-6 order-1 order-md-2">
                <div class="ms-lg-5">
                    <img src="assets/images/directory/paternity.jpg" class="img-fluid" alt="">
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 order-2 order-md-1 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="section-title">
                    <h4 class="title mb-4">Paternity</h4>
                    <p class="text-muted">
                        Paternity test is a medical test used to determine the likelihood that a man is the father of a particular child.
                    </p>
                    <p class="text-muted">
                        DNA samples are collected from the mother, the possible father, and the child for the genetic testing at the laboratory. The laboratory will then issue a report on the results of the genetic testing.
                    </p>
                    <p class="text-primary fw-bold">
                        Paternity testing is HSA eligible expenses. Learn more today.
                    </p>

                    <div class="mt-4">
                        <a href="https://apps.apple.com/us/app/hsa/id1643850395" target="_blank" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
                        <a href="https://play.google.com/store/apps/details?id=com.thinkdiscountllc.hsa" target="_blank" class="btn btn-outline-primary mt-2">
                            <i class="uil uil-google-play"></i> Play
                            Store
                        </a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->


    <div class="container mt-100 mt-60" id="arthritis">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div class="me-lg-5">
                    <img src="assets/images/directory/arthritis.jpg" class="img-fluid" alt="">
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="section-title">
                    <h4 class="title mb-4">Arthritis</h4>
                    <p class="text-muted">
                        Arthritis refers to any type of joint pains or joint diseases that can worsen with age.
                    </p>



                    <p class="text-muted">
                        Arthritis is the leading cause of disability in the United States. There are over 100+ types of arthritis.
                    </p>

                    <p class="text-primary fw-bold">
                        Arthritis testing is HSA eligible expenses. Learn more today.
                    </p>

                    <div class="mt-4">
                        <a href="https://apps.apple.com/us/app/hsa/id1643850395" target="_blank" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
                        <a href="https://play.google.com/store/apps/details?id=com.thinkdiscountllc.hsa" target="_blank" class="btn btn-outline-primary mt-2">
                            <i class="uil uil-google-play"></i> Play
                            Store
                        </a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60" id="inflammation">
        <div class="row align-items-center">
            <div class="col-md-6 order-1 order-md-2">
                <div class="ms-lg-5">
                    <img src="assets/images/directory/inflamation.jpg" class="img-fluid" alt="">
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 order-2 order-md-1 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="section-title">
                    <h4 class="title mb-4">Inflammation</h4>
                    <p class="text-muted">
                        Inflammation is your body's way of protecting your tissues and helping them heal from an injury, infection, or other disease.
                    </p>
                    <p class="text-muted">
                        The test cannot show what is causing the inflammation or which part of your body is inflamed, but can be used as a prevenative measure to detect if anything is going on with your body and begin the diagnosis.
                    </p>
                    <p class="text-primary fw-bold">
                        Inflammation testing is HSA eligible expenses. Learn more today.
                    </p>

                    <div class="mt-4">
                        <a href="https://apps.apple.com/us/app/hsa/id1643850395" target="_blank" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
                        <a href="https://play.google.com/store/apps/details?id=com.thinkdiscountllc.hsa" target="_blank" class="btn btn-outline-primary mt-2">
                            <i class="uil uil-google-play"></i> Play
                            Store
                        </a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60" id="hormone">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div class="me-lg-5">
                    <img src="assets/images/directory/hormones.jpg" class="img-fluid" alt="">
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="section-title">
                    <h4 class="title mb-4">Hormone</h4>
                    <p class="text-muted">
                        Your body uses Hormones to regulate different functions of your body.
                    </p>
                    <p class="text-muted">
                        When your body produces too much or too little of certain hormones (hormone imbalance), your wellbeing can be impacted.
                    </p>


                    <p class="text-primary fw-bold">
                        Hormones testing are HSA eligible expenses. Learn more today.
                    </p>

                    <div class="mt-4">
                        <a href="https://apps.apple.com/us/app/hsa/id1643850395" target="_blank" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
                        <a href="https://play.google.com/store/apps/details?id=com.thinkdiscountllc.hsa" target="_blank" class="btn btn-outline-primary mt-2">
                            <i class="uil uil-google-play"></i> Play
                            Store
                        </a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60" id="vitamin">
        <div class="row align-items-center">
            <div class="col-md-6 order-1 order-md-2">
                <div class="ms-lg-5">
                    <img src="assets/images/directory/vitamins.jpg" class="img-fluid" alt="">
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 order-2 order-md-1 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="section-title">
                    <h4 class="title mb-4">Vitamin</h4>
                    <p class="text-muted">
                        Vitamin is one of the building blocks that help your body perform effectively.
                    </p>


                    <p class="text-muted">
                        Vitamin tests can help diagnose vitamin deficiencies or an excess or overabundance of particular vitamins.
                    </p>

                    <p class="text-primary fw-bold">
                        Vitamin testing is HSA eligible expenses. Learn more today.
                    </p>

                    <div class="mt-4">
                        <a href="https://apps.apple.com/us/app/hsa/id1643850395" target="_blank" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
                        <a href="https://play.google.com/store/apps/details?id=com.thinkdiscountllc.hsa" target="_blank" class="btn btn-outline-primary mt-2">
                            <i class="uil uil-google-play"></i> Play
                            Store
                        </a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60" id="nutritional">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div class="me-lg-5">
                    <img src="assets/images/directory/nutritional.jpg" class="img-fluid" alt="">
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="section-title">
                    <h4 class="title mb-4">Nutritional</h4>
                    <p class="text-muted">
                        Even if you are eating properly and/or taking supplements, chances are, you may still have nutritional deficiencies that could lead to health issues.
                    </p>
                    <p class="text-muted">
                        Nutritional tests can help you learn more about your body and detect nutritional deficiencies early on.
                    </p>
                    <p class="text-primary fw-bold">
                        Nutritional testing is HSA eligible expenses. Learn more today.
                    </p>

                    <div class="mt-4">
                        <a href="https://apps.apple.com/us/app/hsa/id1643850395" target="_blank" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
                        <a href="https://play.google.com/store/apps/details?id=com.thinkdiscountllc.hsa" target="_blank" class="btn btn-outline-primary mt-2">
                            <i class="uil uil-google-play"></i> Play
                            Store
                        </a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60" id="allergy">
        <div class="row align-items-center">
            <div class="col-md-6 order-1 order-md-2">
                <div class="ms-lg-5">
                    <img src="assets/images/directory/allergy.jpg" class="img-fluid" alt="">
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 order-2 order-md-1 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="section-title">
                    <h4 class="title mb-4">Allergy</h4>
                    <p class="text-muted">
                        Severe allergic reactions can be life-threatening for some people. People with a family history of allergies have an increased risk of developing allergic disease.
                    </p>
                    <p class="text-muted">
                        Allergy tests may help find allergies to things you eat, touch, or breathe in.
                    </p>
                    <p class="text-primary fw-bold">
                        Allergy testing is HSA eligible expenses. Learn more today.
                    </p>

                    <div class="mt-4">
                        <a href="https://apps.apple.com/us/app/hsa/id1643850395" target="_blank" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
                        <a href="https://play.google.com/store/apps/details?id=com.thinkdiscountllc.hsa" target="_blank" class="btn btn-outline-primary mt-2">
                            <i class="uil uil-google-play"></i> Play
                            Store
                        </a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60" id="gastrointestinal">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div class="me-lg-5">
                    <img src="assets/images/directory/gastrointestinal.jpg" class="img-fluid" alt="">
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="section-title">
                    <h4 class="title mb-4">Gastrointestinal</h4>
                    <p class="text-muted">
                        Gastrointestinal is a diagnostic test that examines the organs of the upper part of the digestive system.
                    </p>
                    <p class="text-muted">
                        Find out of you have gastrointestinal diseases such as Celiac disease, Irritable Bowel Syndrome (IBS), Lactose Intolerance, Chronic Diarrhea, Constipation, Gastroesophageal Reflux Disease (GERD), Peptic Ulcer Disease, Crohn's Disease etc..
                    </p>
                    <p class="text-primary fw-bold">
                        Gastrointestinal testing is HSA eligible expenses. Learn more today.
                    </p>

                    <div class="mt-4">
                        <a href="https://apps.apple.com/us/app/hsa/id1643850395" target="_blank" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
                        <a href="https://play.google.com/store/apps/details?id=com.thinkdiscountllc.hsa" target="_blank" class="btn btn-outline-primary mt-2">
                            <i class="uil uil-google-play"></i> Play
                            Store
                        </a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60" id="allergy">
        <div class="row align-items-center">
            <div class="col-md-6 order-1 order-md-2">
                <div class="ms-lg-5">
                    <img src="assets/images/directory/vegan.jpg" class="img-fluid" alt="">
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 order-2 order-md-1 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="section-title">
                    <h4 class="title mb-4">Vegan</h4>
                    <p class="text-muted">
                        Vegan tests helps you determine if you are allergic to any animal-derived food.
                    </p>
                    <p class="text-primary fw-bold">
                        Vegan testing is HSA eligible expenses. Learn more today.
                    </p>

                    <div class="mt-4">
                        <a href="https://apps.apple.com/us/app/hsa/id1643850395" target="_blank" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
                        <a href="https://play.google.com/store/apps/details?id=com.thinkdiscountllc.hsa" target="_blank" class="btn btn-outline-primary mt-2">
                            <i class="uil uil-google-play"></i> Play
                            Store
                        </a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60" id="gastrointestinal">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div class="me-lg-5">
                    <img src="assets/images/directory/drug.jpg" class="img-fluid" alt="">
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="section-title">
                    <h4 class="title mb-4">Drug</h4>
                    <p class="text-muted">
                        A drug test analyzes your example urine, hair, blood, breath, sweat, or oral fluid/saliva samples to determine the presence or absence of specified drugs in your body.
                    </p>
                    <p class="text-primary fw-bold">
                        Drug testing is HSA eligible expenses. Learn more today.
                    </p>

                    <div class="mt-4">
                        <a href="https://apps.apple.com/us/app/hsa/id1643850395" target="_blank" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
                        <a href="https://play.google.com/store/apps/details?id=com.thinkdiscountllc.hsa" target="_blank" class="btn btn-outline-primary mt-2">
                            <i class="uil uil-google-play"></i> Play
                            Store
                        </a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->


</section>
<!--end section-->
<!-- End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->