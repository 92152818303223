<!-- Hero Start -->
<section class="bg-half-120 bg-light d-table w-100" style="background: url('assets/images/seo/bg.png') center center;"
    id="home">
    <div class="container">
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="title-heading">
                    <h4 class="heading mt-2 mb-2">HSA Eligible</h4>
                    <h5 class="heading mt-2 mb-4 text-primary">Prescriptions</h5>
                    <p class="para-desc mx-auto text-muted mb-0">Learn more about HSA qualifing prescriptions, browse HSA-Shop Directory today.</p>

                    <div class="mt-4 mb-4">
                        <a href="https://apps.apple.com/us/app/hsa/id1643850395" target="_blank" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
                        <a href="https://play.google.com/store/apps/details?id=com.thinkdiscountllc.hsa" target="_blank" class="btn btn-outline-primary mt-2">
                            <i class="uil uil-google-play"></i> Play
                            Store
                        </a>
                    </div>                   
                </div>
            </div>
            <!--end col-->

        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--end section-->
<!-- Hero End -->

<!-- FEATURES START -->
<section class="section">

    <div class="container mt-60 mb-20">
        <div class="row align-items-center">
            <div class="col-md-12 text-center">
                <a routerLink="/directory/prescriptions" fragment="glasses" class="btn btn-pills btn-soft-primary mt-1"> Glasses </a>
                &nbsp;
                <a routerLink="/directory/prescriptions" fragment="contactlens" class="btn btn-pills btn-soft-primary mt-1"> Contact Lens </a>
                &nbsp;
                <a routerLink="/directory/prescriptions" fragment="vitamins" class="btn btn-pills btn-soft-primary mt-1"> Vitamins </a>
            </div>
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60" id="glasses">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div class="me-lg-5">
                    <img src="assets/images/directory/glasses.jpg" class="img-fluid" alt="">
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="section-title">
                    <h4 class="title mb-4">Glasses</h4>
                    <p class="text-muted">
                        Browser various glasses options in our HSA directory.
                    </p>                   

                    <p class="text-primary fw-bold">
                        Prescriptive glasses are HSA eligible. Learn more today.
                    </p>

                    <div class="mt-4">
                        <a href="https://apps.apple.com/us/app/hsa/id1643850395" target="_blank" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
                        <a href="https://play.google.com/store/apps/details?id=com.thinkdiscountllc.hsa" target="_blank" class="btn btn-outline-primary mt-2">
                            <i class="uil uil-google-play"></i> Play
                            Store
                        </a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60" id="contactlens">
        <div class="row align-items-center">
            <div class="col-md-6 order-1 order-md-2">
                <div class="ms-lg-5">
                    <img src="assets/images/directory/contactlens.jpg" class="img-fluid" alt="">
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 order-2 order-md-1 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="section-title">
                    <h4 class="title mb-4">Contact Lenses</h4>
                    <p class="text-muted">
                        Browser various contact lens options in our HSA directory.
                    </p>
                    <p class="text-primary fw-bold">
                        Prescriptive contact lenses are HSA eligible. Learn more today.
                    </p>

                    <div class="mt-4">
                        <a href="https://apps.apple.com/us/app/hsa/id1643850395" target="_blank" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
                        <a href="https://play.google.com/store/apps/details?id=com.thinkdiscountllc.hsa" target="_blank" class="btn btn-outline-primary mt-2">
                            <i class="uil uil-google-play"></i> Play
                            Store
                        </a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60" id="vitamins">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div class="me-lg-5">
                    <img src="assets/images/directory/vitamins.jpg" class="img-fluid" alt="">
                </div>
            </div>
            <!--end col-->

            <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="section-title">
                    <h4 class="title mb-4">Vitamins</h4>
                    <p class="text-muted">
                        Vitamins are a group of substances that are needed for normal cell function, growth, and development. They are essentials for the body to function properly.
                    </p>
                    <p class="text-muted">
                        Our body cannot synthesize nutrients, and therefore, we can only obtain them through diets.s
                    </p>
                    <p class="text-primary fw-bold">
                        Vitamins and health supplements are HSA-qualified expenses only if they have been recommended by a medical or health professional for the treatment or prevention of a specific disease or condition. Learn more today.
                    </p>
                    <div class="mt-4">
                        <a href="https://apps.apple.com/us/app/hsa/id1643850395" target="_blank" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
                        <a href="https://play.google.com/store/apps/details?id=com.thinkdiscountllc.hsa" target="_blank" class="btn btn-outline-primary mt-2">
                            <i class="uil uil-google-play"></i> Play
                            Store
                        </a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

</section>
<!--end section-->
<!-- End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->