import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-business-benefits',
    templateUrl: './business.benefits.component.html',
    styleUrls: ['./business.benefits.component.css']
})

/**
 * Business Benefits Component
 */
export class BusinessBenefitsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
