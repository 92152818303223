import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.css']
})

/***
 * Products component
 */
export class ProductsComponent implements OnInit {

  /**
   * Products Data
   */
  filterredImages;
  galleryFilter = 'all';
    list = [{
            uilIcon: 'cart',
            url: 'https://www.kqzyfj.com/click-100720344-13697759',
            linkText: 'Get Tested Now',
            image: 'assets/images/work/1.jpg',
            title: 'Get Tested For HIV 1 & 2 Antibody',
            brandLogo: '<a href="https://www.anrdoezrs.net/click-100720344-11961296" target="_blank"><img src="https://www.lduhtrp.net/image-100720344-11961296" width="168" height="28" alt="" border="0" /></a>',
            brand: 'STDCheck.com',
            hasCoupon: true,
            isMobileFriendly: true,
            type: 'Branding',
            category: 'branding',
            adId: '13697759'
        },
        {
            uilIcon: 'cart',
            url: 'https://www.kqzyfj.com/click-100720344-13697771',
            linkText: 'Get Tested Now',
            image: 'assets/images/work/1.jpg',
            title: 'Get Tested For Chlamydia',
            brandLogo: '<a href="https://www.anrdoezrs.net/click-100720344-11961296" target="_blank"><img src="https://www.lduhtrp.net/image-100720344-11961296" width="168" height="28" alt="" border="0" /></a>',
            brand: 'STDCheck.com',
            hasCoupon: true,
            isMobileFriendly: true,
            type: 'Branding',
            category: 'branding',
            adId: '13697771'
        },
        {
    code: 'https://www.anrdoezrs.net/click-100720344-13352876',
    image: 'assets/images/work/3.jpg',
    title: 'x10 Panel Tests',
    type: 'Abstract',
    category: 'photography'
  },
  {
    image: 'assets/images/work/4.jpg',
    title: 'Yellow bg with Books',
    type: 'Books',
    category: 'development'
  },
  {
    image: 'assets/images/work/5.jpg',
    title: 'Company V-card',
    type: 'V-card',
    category: 'branding'
  },
  {
    image: 'assets/images/work/6.jpg',
    title: 'Mockup box with paints',
    type: 'Photography',
    category: 'branding'
  },
  {
    image: 'assets/images/work/7.jpg',
    title: 'Coffee cup',
    type: 'Cups',
    category: 'designing'
  },
  {
    image: 'assets/images/work/8.jpg',
    title: 'Pen and article',
    type: 'Article',
    category: 'development'
  },
  {
    image: 'assets/images/work/9.jpg',
    title: 'White mockup box',
    type: 'Color',
    category: 'photography'
  },
  {
    image: 'assets/images/work/10.jpg',
    title: 'Logo Vectors',
    type: 'Logos',
    category: 'photography'
  },
  {
    image: 'assets/images/work/11.jpg',
    title: 'Black and white T-shirt',
    type: 'Clothes',
    category: 'branding'
  },
  {
    image: 'assets/images/work/12.jpg',
    title: 'Yellow bg with cellphone',
    type: 'Cellphone',
    category: 'branding'
  }
  ];

  constructor() { }

  ngOnInit(): void {
    this.filterredImages = this.list;
  }

  /***
   * Active all category selected
   */
  activeCategory(category) {
    this.galleryFilter = category;
    if (this.galleryFilter === 'all') {
      this.filterredImages = this.list;
    } else {
      this.filterredImages = this.list.filter(x => x.category === this.galleryFilter);
    }
  }

}
