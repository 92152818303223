<!-- Hero Start -->
<section class="bg-half-100 bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> About Us </h4>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb bg-white rounded shadow mb-0">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Resources</a></li>
                    <li class="breadcrumb-item active" aria-current="page">About Us</li>
                </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- About Start -->
<section class="section">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="position-relative">
          <img src="assets/images/piggy_bank_money_savings.jpg" class="rounded img-fluid mx-auto d-block" alt="HSA-Shop About Us - Cofounder story on discovering savings on HSA-Eligible products and our inspiration on how we want to help educate people to make better choices on how they can save more money on their health expenses by making it easy for anyone to locate HSA-Eligible products.">
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
          <div class="section-title ms-lg-4">
              <h4 class="title mb-4">Our Story</h4>
              <p class="text-muted">
                  One of our cofounders had recently discovered that Health Savings Accounts (HSA) can be use to purchase over the counter medicines in addition to regular medical and dental expenses.
                  <br/><br/>
                  That is when we realized that while Health Savings Accounts (HSA) has become more widely adopted by people, the knowledge of HSA-Eligible products that can be purchased using HSA may not be obvious to some people.
                  <br/><br/>
                  <span class="text-primary fw-bold">We are inspired to help educate people to make better choices on how they can save more money on their health expenses by making it easy for anyone to locate HSA-Eligible products.</span>
                  <br /><br />
                  HSA-Shop is your one stop directory with over 7000+ of HSA-Eligible products available in savings in pre-tax.
                  <br /><br />
                  Please help us spread the word. Download our application to browse our Health Savings Accounts (HSA) eligible products directory today!
              </p>
              <a href="https://apps.apple.com/us/app/hsa/id1643850395" target="_blank" class="btn btn-primary m-1"><i class="uil uil-apple"></i> App Store</a>
              &nbsp;
              <a href="https://play.google.com/store/apps/details?id=com.thinkdiscountllc.hsa" target="_blank" class="btn btn-outline-primary m-1">
                  <i class="uil uil-google-play"></i> Play
                  Store
              </a>
          </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

</section>
<!--end section-->
<!-- About End -->
