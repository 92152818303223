import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgxMasonryOptions } from 'ngx-masonry';
import { HttpService } from '../../../../services/http.service';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-business-directory',
    templateUrl: './business.directory.component.html',
    styleUrls: ['./business.directory.component.css']
})
export class BusinessDirectoryComponent implements OnInit {

    businessname: string = '';
    contactname: string = '';
  email: string = '';
  phone: string = '';
  website: string = '';
  listing: string = '';

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    autoplay: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      900: {
        items: 3
      }
    },
    nav: false,
  };

  seoAgency = [
    {
      images: 'assets/images/seo/6.png',
    },
    {
      images: 'assets/images/seo/4.png',
    },
    {
      images: 'assets/images/seo/7.png',
    },
    {
      images: 'assets/images/seo/5.png',
    },
    {
      images: 'assets/images/seo/3.png',
    },
    {
      images: 'assets/images/seo/1.png',
    },
    {
      images: 'assets/images/seo/2.png',
    }
  ];

  /**
   * Services Data
   */
  servicesData = [
    {
      icon: "uil uil-chart-line h1 text-primary",
      title: "Get Quality Leads",
      description: "Thousands of our customers are looking qualifing for HSA-Eligible products or services for their personal needs. Your offers can be a great match."
    },
    {
      icon: "uil uil-adjust-circle h1 text-primary",
      title: "Outreach to Customers",
      description: "Notify customers when your health products or services are available in the directory, directly through our mobile application."
    },
    {
      icon: "uil uil-award h1 text-primary",
      title: "Drive More Sales",
      description: "Drive value to your business by putting your health products or services right infront of the customer via mobile device."
    }
  ];

  /**
   * Client Testimonial Data
   */
  testimonialData = [
    {
      profile: "assets/images/client/01.jpg",
      name: "Thomas Israel",
      designation: "C.E.O",
      message: `" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. "`
    },
    {
      profile: "assets/images/client/02.jpg",
      name: "Barbara McIntosh",
      designation: "M.D",
      message: `" One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others. "`
    },
    {
      profile: "assets/images/client/03.jpg",
      name: "Carl Oliver",
      designation: "P.A",
      message: `" The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century. "`
    },
    {
      profile: "assets/images/client/04.jpg",
      name: "Christa Smith",
      designation: "Manager",
      message: `" According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero. "`
    },
    {
      profile: "assets/images/client/05.jpg",
      name: "Dean Tolle",
      designation: "Developer",
      message: `" There is now an abundance of readable dummy texts. These are usually used when a text is required. "`
    },
    {
      profile: "assets/images/client/06.jpg",
      name: "Jill Webb",
      designation: "Designer",
      message: `" Thus, Lorem Ipsum has only limited suitability as a visual filler for German texts. "`
    }
  ];

  isActive: string = "month";

  /**
   * Tab change value
   * @param value
   */
  changeTab(value: string) {
    this.isActive = value;
  }

    submitBusinessDirectoryForm() {
        if (this.businessname.trim() == '' || this.contactname.trim() == '' || this.email.trim() == ''
            || this.phone.trim() == '' || this.listing.trim() == '') {
          alert('Please include your business name, contact name, email, phone, website and message to us.');
          return;
        }

        let promise = this.http.businessDirectoryForm(this.businessname, this.contactname, this.email, this.phone, this.website, this.listing).toPromise();
        alert('Thank you for contacting us, we will get back to you soon.');
  }

    constructor(private http: HttpService,
        private titleService: Title, private metaService: Meta) { }

    ngOnInit(): void {
        const content: any = {
            title: "HSA-Shop - Business Directory - Learn More and Sign Up",
            description: "Sign up and learn more about HSA-Shop Business Directory and how you can promote your business.",
            robots: "index,follow",
            ogType: "website",
            ogUrl: "www.hsa-shop.net/business/directory",
            ogImage: "www.hsa-shop.net/assets/images/mobileapp/app_landing.PNG",
            ogSiteName: "HSA-Shop",
            twitterUsername: "",
            viewport: "width=device-width,initial-scale=1.0"
        }

        this.titleService.setTitle(content.title);

        const description: MetaDefinition = {
            name: "description",
            content: content.description
        }

        const robots: MetaDefinition = {
            name: "robots",
            content: content.robots
        }

        const ogType: MetaDefinition = {
            name: "og:type",
            content: content.ogType
        }

        const ogTitle: MetaDefinition = {
            name: "og:title",
            content: content.title
        }

        const ogDescription: MetaDefinition = {
            name: "og:description",
            content: content.description
        }

        const ogImage: MetaDefinition = {
            name: "og:image",
            content: content.ogImage
        }

        const ogUrl: MetaDefinition = {
            name: "og:url",
            content: content.ogUrl
        }

        const ogSiteName: MetaDefinition = {
            name: "og:site_name",
            content: content.ogSiteName
        }

        const twitterTitle: MetaDefinition = {
            name: "twitter:title",
            content: content.title
        }

        const twitterDescription: MetaDefinition = {
            name: "twitter:description",
            content: content.description
        }

        const twitterImage: MetaDefinition = {
            name: "twitter:image",
            content: content.ogImage
        }

        const twitterUrl: MetaDefinition = {
            name: "twitter:url",
            content: content.ogUrl
        }

        // <meta name="twitter:site" content="@USERNAME">
        // <meta name="twitter:creator" content="@USERNAME">

        const viewport: MetaDefinition = {
            name: "viewport",
            content: content.viewport
        }


        const keywords: MetaDefinition = {
            name: "keywords",
            content: "hsa, health savings account, hsa shop contact us, hsa shop privacy policy, hsa shop, hsa store, hsa eligible, hsa eligible products, hsa product directory, hsa savings, health business directory, health small business, health mid-size business, health enterprise business, health non-profit organization"
        }

        this.metaService
            .addTags([description, robots, ogType, ogTitle,
                ogDescription, ogUrl, ogSiteName, ogImage, twitterImage,
                twitterTitle, twitterDescription, twitterUrl,
                viewport, keywords]);
    }

    ngOnDestroy(): void {
        this.titleService.setTitle("HSA-Shop - Your HSA-Eligible Product Directory Store");
        this.metaService.removeTag("name='description'");
        this.metaService.removeTag("name='robots'");
        this.metaService.removeTag("name='og:type'");
        this.metaService.removeTag("name='og:title'");
        this.metaService.removeTag("name='og:description'");
        this.metaService.removeTag("name='og:image'");
        this.metaService.removeTag("name='og:url'");
        this.metaService.removeTag("name='og:site_name'");
        this.metaService.removeTag("name='twitter:title'");
        this.metaService.removeTag("name='twitter:description'");
        this.metaService.removeTag("name='twitter:image'");
        this.metaService.removeTag("name='twitter:url'");
        this.metaService.removeTag("name='viewport'");
        this.metaService.removeTag("name='keywords'");
    }

}
